import IconButton from "@material-ui/core/IconButton";
import ClearAllIcon from "@material-ui/icons/LayersClearOutlined";
import FilterListIcon from "@material-ui/icons/FilterList";
import Toolbar from "@material-ui/core/Toolbar";
import { groupBy } from "lodash";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { IReport } from "../Library/types";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import reportIcon from '../Assets/images/report-image.svg';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(createStyles({
    img: {
        objectFit: 'contain',
        width:30,
        height:30
    },
    root: {
        borderRadius: 0,
    },
    item: {
        width: '100%',        
        cursor: 'pointer',        
        position: 'relative',        
        boxShadow: '0 3px 3px 3px rgba(0, 0, 0, 0.5);',
        backgroundColor:'#fff',
        marginTop: 20,
        borderRadius: '15px'
    },
    icon: {
        paddingTop: '3px',
        paddingBottom: '3px',
        color: '#284770',
        verticalAlign: 'middle',
    },

    promotion: {
        //backgroundColor: '#f00',
        display: 'inline-block',
        padding: '3px 15px',
        //color: '#fff',
        verticalAlign: 'middle',
    },
    separator: {
        display: 'inline-block',
        borderRight: '1px solid rgba(0,0,0,0.31)',
        verticalAlign: 'middle',
        height: '100%',
        marginLeft: '15px'
    },
    textField: {
        marginLeft: '10px',
        marginRight: '10px',
        width: 60,
    },
    mainHeader: {
        whiteSpace:'nowrap',
        overflowX:'auto',
        textTransform:'capitalize'
    }
}));


type Config = {
    handleClose: Function;
    items: Array<IReport>;
    handleClickReport: Function;
    loading: boolean;
};

const AvailableReports = (props: Config) => {
  const {
    items,
    handleClose
  } = props;

  const classes = useStyles();

  const fields = 
    items.map((item:IReport, index: number)=>{
        return (
            <ListItem style={{ opacity: [0].indexOf(item.type) == -1?0.5:1 }} disabled={props.loading} classes={{ root: classes.item }} onClick={()=>props.handleClickReport(item)}>
                <ListItemIcon>              
                    <Avatar alt="Report Icon" src={reportIcon} classes={{
                        root: classes.root,
                        img: classes.img
                    }} />                    
                </ListItemIcon>
                <ListItemText 
                primary={`${item.id} - ${item.description}`} 
                // secondary={`${item.id}`} 
                
                className={classes.mainHeader} />
            </ListItem>
        )
    });

  return (
    <Dialog fullScreen={false} open={true} aria-labelledby="form-dialog-title" className='dialog-little'>
        <DialogTitle id="form-dialog-title">{'Partes disponibles'}</DialogTitle>
        <DialogContent>
            {fields.length == 0?
                `No hay partes disponibles a realizar para este tipo de granja.`
            :
                fields
            }
        </DialogContent>
        <DialogActions>
            <div style={{ width:'100%', textAlign:'center' }}>
                <Button onClick={()=>props.handleClose()} className="cancel-dialog">
                    {'Cerrar'}
                </Button>
            </div>
        </DialogActions>
    </Dialog>
  );
};

export default AvailableReports;