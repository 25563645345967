import React, { Component } from 'react';
import './App.css';
import './Components/Sidebar.css';
import './Components/Layout.css';

import './Components/Material/activityProgress.css';
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { IData, IMarker, IMe, IFilter } from './Library/types';

import OutOfService from './Components/OutOfService';
import Unauthorized from './Components/Unauthorized';
import OrderService from './ApiClients/OrderService';
import Home from './Components/Home';
import Farms from './Components/Farms';
import Farm from './Components/Farm';
import Reports from './Components/Reports';
import Report from './Components/Report';

import appData from './ApiClients/appData';
import {removeItemLocalStorage, findAndModifyFirst} from './Library/vc_general';
import { cloneDeep } from 'lodash';

import './Components/themes/Theme_01';

let timerGetData:any = undefined;

let version:string = 'v1.2.1';

const i_filters:IFilter = {farms: {}, prediction: 0, products: {}, date: {}, workstation: {}, data: [], withTag: 0};

const farm = {marker:undefined, data: {navbar:undefined, dashboards:undefined, alarmsA: undefined, alarmsH: undefined}};

interface IAppProps {
  //history: any;
  //match:any;
}

interface IAppState {
  myInfo?: IMe;
  markers:Array<IMarker>;
  loadedMarker?:IMarker;
  farmData:any;
  loading:boolean;
  loadingmap:boolean;
  dataDevices?:Array<IData>;
  ninformed: boolean;
  filters: IFilter;
}

class App extends Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      myInfo: undefined,
      filters:i_filters,
      markers:[],
      loading:false,
      loadingmap:true,
      farmData: farm,
      ninformed: false
    };
  }

  componentDidMount(){
    // Evita que el usuario pueda hacer zoom
    /*document.addEventListener('touchmove', function (event:any) {
      if (event.scale !== 1) { event.preventDefault(); }
    }, { passive: false });*/

    this.getMyInfo();
    //this.resetLocalStorage();
  }

  /*componentDidMount(){
    //this.loadMap();
  }*/

  resetLocalStorage=()=>{
    /*removeItemLocalStorage("searchFarm");
    removeItemLocalStorage("mapfilters");*/

  }

  metrics = async (metricType:number) => { // Registra el acceso del usuario, el fallo no autorizado y el fallo por mantenimiento
    //await OrderService.metricService(metricType);
  }

  getMyInfo = async () => {
    let response = await OrderService.me();
    this.setState({myInfo:response})
    //this.metrics(1); // Guarda el registro del primer Login
    /*this.checkUserPermission(response);*/
  }
    
  checkUserPermission=(inputInfo:IMe|undefined)=>{ // Verifica los permisos del usuario //

    if(inputInfo) {
      if(!inputInfo.activated) { // Actualiza el estado MyInfo en caso de haber obtenido correctamente la información del usuario
        this.setState({ myInfo: undefined });
        //history('/noAutorizado');
        this.metrics(2);
      } else {
        this.setState({ myInfo: inputInfo });
      }
    } else if(inputInfo == undefined){
      //history('/noAutorizado');
      this.metrics(2);
    } else {
      //history('/noAutorizado');
    }
  }

  handleCheckFilter = () => {    
    this.setState({filters: i_filters});
  }

  handleChangeFilter = (id:string, filters:any) => {
    let f = cloneDeep(this.state.filters);
    f[id] = filters;
    this.setState({filters: f});
  }

  render() {
    return (
        <Routes>
          <Route
            path="/"
            element={
              <Home
                //match={this.props.match}
                //history={this.props.history}
                myInfo={this.state.myInfo}
                version={version}
              />
            }
          />
          <Route
            path="/farms"
            element={
              <Farms
                //match={this.props.match}
                //history={this.props.history}
                myInfo={this.state.myInfo}
                version={version}
                handleChangeFilter={this.handleChangeFilter}
                filters={this.state.filters}
              />
            }
          />
        <Route
          path="/reports"
          element={
            <Reports
              //match={this.props.match}
              //history={this.props.history}
              myInfo={this.state.myInfo}
              version={version}
              handleChangeFilter={this.handleCheckFilter}
              filters={this.state.filters}
            />
          }
        />
        <Route
          path="/report/:business/:reportId"
          element={
            <Report
              //match={this.props.match}
              //history={this.props.history}
              myInfo={this.state.myInfo}
              version={version}
            />
          }
        />
        <Route
          path="/noAutorizado"
          element={
            <Unauthorized
              //history={this.props.history}
              refreshMyInfo={this.getMyInfo}
              myInfo={this.state.myInfo}
            />
          }
        />
        <Route
          path="/fueraServicio"
          element={
            <OutOfService
              //history={this.props.history}
              refreshMyInfo={this.getMyInfo}
              myInfo={this.state.myInfo}
            />
          }
        />
        <Route 
          path='/farm/:business/:id'
          element={
            <Farm                 
              myInfo={this.state.myInfo}
              version={version}
            />
          }
        />
        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />
      </Routes>
    );
  }
}

export default App;
