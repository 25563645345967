import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SearchField from './Material/SearchField';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/MenuOutlined';
import NotificationsIcon from '@material-ui/icons/Notifications';
import UserIcon from '@material-ui/icons/AccountCircleRounded';
import Map from '@material-ui/icons/MapOutlined';
import SettingIcon from '@material-ui/icons/SettingsOutlined';
import Drawer from './Sidebar';
import { authContext } from '../Library/adalConfig';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IFilter, IMarker, IMe } from '../Library/types';
import {comeFromSmartCenter, GetRole} from '../Library/helpers';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LateralMenu from './LateralMenu';
import Typography from '@material-ui/core/Typography';
import ExitApp from '@material-ui/icons/ExitToAppOutlined';
import SvgIcon from '@mui/material/SvgIcon';
// import AppIcon from '../Assets/nealia_health_icon.png';
import AppIcon from '../Assets/logo-health-icon.svg';
import LoaderBeauty from './Material/activityProgress';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        position:'fixed',
        top:0,
        width:'100%',
        zIndex:100
      },
      menuButton: {
        marginRight: '1em',
      },
      title: {
        textAlign: 'center',
      
        overflowX:'auto'
      },
      
});

interface ILayoutProps {    
    version: string;
    me?:IMe;
    showLateralMenu: boolean;
    title?:string;
    navigate:any;
    loadbeauty?:boolean;
}

export default function Layout(props: ILayoutProps) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [lateralMenu, setLateralMenu] = React.useState(false);

    const open = Boolean(anchorEl);
    const drawerOpenned = Boolean(lateralMenu);

    const handleToogle = (event: React.MouseEvent<HTMLElement>) => {
        setLateralMenu(!drawerOpenned);
    };
    
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuOption=(option: number)=>{
        switch(option){
            case 1: // Cerrar sesion
                authContext.logOut();
            break;
        }
    }

    return(
        <div>
            <AppBar style={{backgroundColor:'#6D6D6D', height:55, overflowX:'auto'}} position="static">
                    <table cellSpacing={0} cellPadding={0} style={{width:'100%', minWidth:400}}>
                        <tbody>
                            <tr>
                                <td style={{width:'20%'}}>
                                    <div style={{display:'flex', alignItems:'center', justifyContent:'start', width:'100%'}}>
                                        <img style={{ cursor:'pointer' }} onClick={()=>props.navigate(`/`)} height={50} width={'auto'} src={AppIcon}></img>
                                    </div>
                                </td>
                                
                                <td style={{ width:'60%', textAlign:'center', marginLeft:'auto', marginRight:'auto', maxWidth:250 }}>
                                    {/* <Toolbar>                         */}
                                        <Typography className={classes.title} style={{ whiteSpace:'nowrap', maxWidth:'90%', marginLeft:'auto', marginRight:'auto' }}>
                                            {props.title}
                                        </Typography>                                
                                    {/* </Toolbar> */}
                                </td>
                                <td style={{width:'20%', textAlign:'right', justifyContent:'right', maxWidth:'250px'}}>
                                    <div style={{ display:'flex', justifyContent:'right' }}>
                                        <span className='bar-user-name'>{props.me?.userIdName}</span> 
                                        
                                        <IconButton
                                            aria-label="account of current user"
                                            aria-controls="menu-appbar"
                                            aria-haspopup="true"
                                            onClick={handleMenu}
                                            color="inherit"
                                        >
                                            <AccountCircle />                    
                                        </IconButton>
                                        <div className="vl menuIcon"></div>
                                        <span className='menuIcon'>
                                            <IconButton onClick={handleToogle}>
                                                <MenuIcon style={{fill:'#fff'}} />
                                            </IconButton>
                                        </span>
                                    </div>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                    <div>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                            style={{ marginTop:40}}
                        >
                        <div className='menu-user-name'>
                            <MenuItem style={{borderTop:'1px solid #ff9000'}} disabled ><strong style={{fontSize:'12px'}}>{`${props.me?.userIdName}`}</strong></MenuItem>                        
                        </div>
                        <div onClick={()=>handleMenuOption(1)} style={{display:'flex',paddingLeft:10, marginTop:0, borderTop:'1px solid #ff9000'}}>
                            {/* <img style={{margin:'auto', cursor:'pointer'}} width={'20px'} src={LogoutIcon} alt='cerrar sesión'/> */}
                            <i style={{  color:'#ff9000', alignSelf:'center' }}>
                                <SvgIcon component={ExitApp}  />
                            </i>                            
                            <MenuItem style={{ width:'180px' }}/*style={{borderTop:'2px solid #ddd'}}*/>{`Cerrar Sesión`}</MenuItem>
                        </div>              
                        <MenuItem style={{borderTop:'1px solid #ff9000'}} disabled ><strong style={{fontSize:'12px'}}>{`${props.version}`}</strong></MenuItem>
                        </Menu>
                    </div>
            </AppBar>

            {props.showLateralMenu?
                <LateralMenu                        
                        me={props.me}                        
                        opened={drawerOpenned}
                        navigate={props.navigate}
                    />
                :
                    null
            }
            <LoaderBeauty visible={props.loadbeauty?props.loadbeauty:false}/>
        </div>
    );
}
