import IconButton from "@material-ui/core/IconButton";
import ClearAllIcon from "@material-ui/icons/LayersClearOutlined";
import FilterListIcon from "@material-ui/icons/FilterList";
import Toolbar from "@material-ui/core/Toolbar";
import { cloneDeep, filter, findIndex, groupBy } from "lodash";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import CreateModal from './Material/CreateDialog'

import { IBatch, IBuilding, IFarm, IMe, IOrigin, IReport } from "../Library/types";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import reportIcon from '../Assets/images/report-image.svg';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { SelectorField, CheckBoxField, TextFieldInput } from './FormsWidgets';
import React, { useEffect } from "react";
import { ValidateText } from "../Library/vc_general";
import { Paper, TableCell, Table, TableHead, TableRow, TableBody, TableContainer, withStyles, Theme } from "@material-ui/core";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import DatePicker from './Material/DatePicker';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = (theme: Theme) => createStyles({
    badge: {
        top: '10px',
        right: '5px',
    }
});

type GetHomogeneityConfig = {
    handleClose: Function;
    c_weights: Array<number>;
    unit: string;
    handleRetHomogeneity: Function;
    disabled:boolean;
};

const GetHomogeneity = (props: GetHomogeneityConfig) => {
    const {
        c_weights,
        unit,
        handleClose,
    } = props;

    const classes = useStyles();
    const [weights, setWeights] = React.useState<Array<number>>(c_weights);
    const [weight, setWeight] = React.useState(0);

    const handleAddWeight = (weight:number) => {
        let d = cloneDeep(weights);
        d.push(weight);
        setWeights(d);
        setWeight(0);
    }

    const handleDeleteWeight = (idx:number) => {
        let data = cloneDeep(weights);
        data.splice(idx, 1);
        setWeights(data);
    }

    const handleChangeData = (id: string, evt:any, type?:string) => {
        
        let txt:any = ValidateText("int", evt.target.value, false)

        if(txt[0])
            setWeight(txt[1]);
    }

    return (
        <React.Fragment>
            <Dialog fullScreen={false} open={true} aria-labelledby="form-dialog-title" className='dialog-little'>
                <DialogTitle id="form-dialog-title">
                    {`Registro pesadas`}
                    <br/>
                    {!props.disabled?
                        <div style={{ display:'flex', margin:'auto'}}>
                            <TextFieldInput
                                key={0}
                                name={"Peso"}
                                id={"weight"}
                                fieldsData={{ weight: weight }}
                                handleFieldChange={(id:string, evt:any)=>handleChangeData(id, evt)}
                                // disabled={[50, 97, 98, 99].indexOf(item.estado) == -1?(n.group.indexOf(myRole) == -1?true:false):true}
                                disabled={false}
                                size="medium"
                                shrink={true}
                                helper={`Peso en ${props.unit}`}
                                maxText={100000}
                                type={"text"}
                                required={true}
                                style={1}
                                //noError={n.required?this.verifyForm(0,n.id):true}
                                noError={weight > 0?true:false}
                                rows={3}
                                className="color-sky-blue"
                            />
                            <IconButton disabled={weight > 0?false:true} size="small" onClick={()=>handleAddWeight(weight)}>
                                <AddIcon style={{fill:'#004d00'}} />
                            </IconButton>
                        </div>
                    :
                        null
                    }
                </DialogTitle>
                <DialogContent>
                    <Paper>
                        <TableContainer style={{maxHeight: '30vh', minHeight:'30vh'}}>                            
                        <Table stickyHeader>
                            <TableHead >
                                <TableRow >
                                    <TableCell style={{ fontWeight:'bold' }} align="center">
                                        {`Nº`}
                                    </TableCell>
                                    <TableCell style={{ fontWeight:'bold' }} align="center">
                                        {`Peso`}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {weights.map((c_weight:number, idx:number)=>{
                                    return (
                                        <TableRow>
                                            <TableCell align="center">
                                                {idx + 1}
                                            </TableCell>
                                            <TableCell align="center">
                                                {c_weight}
                                            </TableCell>
                                            {!props.disabled?
                                                <TableCell align="center">
                                                    <IconButton size="small" onClick={()=>handleDeleteWeight(idx)}>
                                                        <DeleteIcon style={{fill:'#6d6d6d'}} />
                                                    </IconButton>
                                                </TableCell>
                                            :
                                                null
                                            }
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                        </TableContainer>

                    </Paper>
                </DialogContent>
                <DialogActions>
                    <div style={{ display:'flex', gap:20 }}>
                        <Button onClick={()=>props.disabled?props.handleClose():props.handleRetHomogeneity(weights)} className="cancel-dialog">
                            {'Ok'}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

const Homogeneity = withStyles(styles)(GetHomogeneity);

const useStyles = makeStyles(createStyles({
    img: {
        objectFit: 'contain',
        width:40,
        height:40
    },
    root: {
        borderRadius: 0,
    },
    item: {
        width: '100%',        
        cursor: 'pointer',        
        position: 'relative',        
        boxShadow: '0 3px 3px 3px rgba(0, 0, 0, 0.5);',
        backgroundColor:'#fff',
        marginTop: 20,
        borderRadius: '15px'
    },
    icon: {
        paddingTop: '3px',
        paddingBottom: '3px',
        color: '#284770',
        verticalAlign: 'middle',
    },

    promotion: {
        //backgroundColor: '#f00',
        display: 'inline-block',
        padding: '3px 15px',
        //color: '#fff',
        verticalAlign: 'middle',
    },
    separator: {
        display: 'inline-block',
        borderRight: '1px solid rgba(0,0,0,0.31)',
        verticalAlign: 'middle',
        height: '100%',
        marginLeft: '15px'
    },
    textField: {
        marginLeft: '10px',
        marginRight: '10px',
        width: 60,
    },
    mainHeader: {
        whiteSpace:'nowrap',
        overflowX:'auto'
    }
}));

// const Report00 = 
//     [
//         {
//             id: "reportedCasualties",
//             type: 2,
//             inputType: 'int',
//             required: true,
//             name: "Bajas",
//             answer: "",
//             a_template: [

//             ],
//             comments: null
//         },
//         {
//             id: "weight",
//             type: 2,
//             inputType: 'float',
//             required: true,
//             name: "Peso",
//             answer: "",
//             a_template: [

//             ],
//             comments: null
//         },
//         {
//             id: "cama",
//             type: 1,
//             inputType: null,
//             required: true,
//             name: "Cama",
//             answer: null,
//             a_template: [
//                 {
//                     id:1,
//                     value: "0",
//                     name: "0"
//                 },
//                 {
//                     id:2,
//                     value: "1",
//                     name: "1"
//                 },
//                 {
//                     id:3,
//                     value: "2",
//                     name: "2"
//                 },
//                 {
//                     id:4,
//                     value: "3",
//                     name: "3"
//                 },
//                 {
//                     id:5,
//                     value: "4",
//                     name: "4"
//                 },
//                 {
//                     id:6,
//                     value: "5",
//                     name: "5"
//                 }
//             ],
//             comments: null
//         },
//         // {
//         //     id: "q_4",
//         //     type: 2,
//         //     inputType: 'float',
//         //     required: true,
//         //     name: "Muestreo pesos",
//         //     repeat: 5,
//         //     answer: "",
//         //     a_template: [
                
//         //     ],
//         //     comments: null
//         // },
//         {
//             id: "color",
//             type: 1,
//             inputType: null,
//             required: true,
//             name: "Color",
//             answer: null,
//             a_template: [
//                 {
//                     id:1,
//                     value: "0",
//                     name: "0"
//                 },
//                 {
//                     id:2,
//                     value: "101",
//                     name: "101"
//                 },
//                 {
//                     id:3,
//                     value: "102",
//                     name: "102"
//                 },
//                 {
//                     id:4,
//                     value: "103",
//                     name: "103"
//                 },
//                 {
//                     id:5,
//                     value: "104",
//                     name: "104"
//                 },
//                 {
//                     id:6,
//                     value: "105",
//                     name: "105"
//                 }
//             ],
//             comments: null
//         },
//         // {
//         //     id: "dailyIncreaseWeight",
//         //     type: 2,
//         //     inputType: 'int',
//         //     required: true,
//         //     name: "Incremento gramos días",
//         //     answer: "",
//         //     a_template: [

//         //     ],
//         //     comments: null
//         // },

//         {
//             id: "digestibility",
//             type: 1,
//             inputType: null,
//             required: true,
//             name: "Digestiblidad",
//             answer: null,
//             a_template: [
//                 {
//                     id:1,
//                     value: "0",
//                     name: "0"
//                 },
//                 {
//                     id:2,
//                     value: "1",
//                     name: "1"
//                 },
//                 {
//                     id:3,
//                     value: "2",
//                     name: "2"
//                 },
//                 {
//                     id:4,
//                     value: "3",
//                     name: "3"
//                 },
//                 {
//                     id:5,
//                     value: "4",
//                     name: "4"
//                 },
//                 {
//                     id:6,
//                     value: "5",
//                     name: "5"
//                 }
//             ],
//             comments: null
//         },
//         {
//             id: "pathology",
//             type: 1,
//             inputType: null,
//             required: true,
//             name: "Patología",
//             answer: null,
//             a_template: [
//                 {
//                     id:1,
//                     value: "0",
//                     name: "0"
//                 },
//                 {
//                     id:2,
//                     value: "1",
//                     name: "1"
//                 },
//                 {
//                     id:3,
//                     value: "2",
//                     name: "2"
//                 },
//                 {
//                     id:4,
//                     value: "3",
//                     name: "3"
//                 },
//                 {
//                     id:5,
//                     value: "4",
//                     name: "4"
//                 },
//                 {
//                     id:6,
//                     value: "5",
//                     name: "5"
//                 }
//             ],
//             comments: null
//         },
//         {
//             id: "homogeneity",
//             type: 1,
//             inputType: null,
//             required: true,
//             name: "Homogeniedad",
//             answer: null,
//             a_template: [
//                 {
//                     id:1,
//                     value: "0",
//                     name: "0"
//                 },
//                 {
//                     id:2,
//                     value: "1",
//                     name: "1"
//                 },
//                 {
//                     id:3,
//                     value: "2",
//                     name: "2"
//                 },
//                 {
//                     id:4,
//                     value: "3",
//                     name: "3"
//                 },
//                 {
//                     id:5,
//                     value: "4",
//                     name: "4"
//                 },
//                 {
//                     id:6,
//                     value: "5",
//                     name: "5"
//                 }
//             ],
//             comments: null
//         },
//         {
//             id: "comments",
//             type: 2,
//             inputType: 'text',
//             required: false,
//             name: "Observaciones",
//             answer: "",
//             a_template: [

//             ],
//             comments: null
//         },
//     ]

// const GetTitle = (journalType:number)=>{
//     switch(journalType) {
//         case 0:
//             return "Parte de situación"
//         default:
//             return "unknow"
//     }
// }

const GetForm = (farm:IFarm, me:IMe, headerquestions: Array<any>, linesquestions: Array<any>, report:IReport) => {
    
    let form:any = {
        userId: me.userId,
        journalTable: [
            {
                farmId: farm.id,
                //farmBatchId: "2",
                farmJournalType: report.type,
                journalDateTime: null,
                ServiceFarmJournalId: report.id,
                // controllerId: "pdx02",

                journalVisitor: false,
                journalTenDays: false,
            }
        ]
    };

    let dale:Array<any> = []
    let idxLineNumber = 0;
    
    farm.building.map((item:IBuilding, idx00:number)=>{


        const lote = filter(farm.building[idx00].batch, function(o){ return o.active}).sort((a, b) => new Date(b.openedDate).getTime() - new Date(a.openedDate).getTime());
        if(lote.length == 0)
            return

        const c_lote = lote[0];

        c_lote.animals.map((animal:IOrigin, idx02:number)=>{
            idxLineNumber++;

            // alert(`c_lote.animals: ${idxLineNumber}`)

            let tmp_data:any = {
                journalLineType: 0, //animales, pienso,
                lineNumber: idxLineNumber,
                buildingId: item.buildingId,
                itemId: animal.itemId,
                //unit: "kg", //pienso y animales
                // weight: null,
                // reportedCasualties: null,
                // cama: null,
                // color: null,
                // homogeneity: null,
                // digestibility: null,
                // pathology: null,
                // comments: null,
                Weights: []
            };

            //** Add dynamic questions to lines */
            linesquestions.map((question:any, idx03:number)=>{
                const id = question.id;
                question.a_template.map((item:any, idx04:number)=>{
                    item.id = (idx04 + 1);
                })
                //dale[idx02][id] = null;
                tmp_data[id] = null;
            })

            dale.push(tmp_data);
        })

    })

    const building_group = groupBy(dale, x => x.buildingId);

    form.journalTable[0].farmJournalLines = building_group;

    //** Add dynamic questions to header */
    headerquestions.map((question:any, idx03:number)=>{
        const id = question.id;
        question.a_template.map((item:any, idx04:number)=>{
            item.id = (idx04 + 1);
        })
        //dale[idx02][id] = null;
        form.journalTable[0][id] = null;
    })

    return(
        form
    )
}

type Config = {
    handleClose: Function;
    item: IReport;
    report?: any;
    handleCreateReport: Function;
    farm:IFarm;
    questions:any;
    saving:boolean;
    msg?:string;
    action?:number;
    resetMessage?:Function;
    me:IMe;
};

const CreateReport = (props: Config) => {
    const {
    item,
    handleClose,
    } = props;
    
    useEffect(() => {
        if(props.msg && props.action) {
            setInputText(props.msg);
            setModalType(4);
            setTypeAction(props.action);
        }
    });

    useEffect(() => {
        handleSetLote(building)
    }, []); // See Note 2

    const classes = useStyles();
    const [creationModalOpen, setCreationModalOpen] = React.useState<boolean>(false);
    const [inputText, setInputText] = React.useState<string>("");
    const [modalType, setModalType] = React.useState<number>(-1);
    const [typeAction, setTypeAction] = React.useState<number>(-1);

    const [c_report, setReport] = React.useState<any>(GetForm(props.farm, props.me, props.questions[0], props.questions[1], props.item));

    const [ret, setRet] = React.useState<any>(undefined);

    const [building, setBuilding] = React.useState(0);

    const [origin, setOrigin] = React.useState(0);

    const [lote, setLote] = React.useState<IBatch|undefined>(undefined);

    const [openHomogeneity, setHomogeneity] = React.useState<boolean>(false);

    const handleSetLote = (s_building:number) => {
        if(props.farm.building.length == 0)
            setLote(undefined);
        else {
            const lotes = filter(props.farm.building[s_building].batch, function(o){ return o.active}).sort((a, b) => new Date(b.openedDate).getTime() - new Date(a.openedDate).getTime());

            if(lotes.length == 0)
                setLote(undefined);
            else
                setLote(lotes[0]);
        }
    }

    const handleCreateModal = (typeAction: number) => {
        switch(typeAction) {
            case 1:
                props.handleCreateReport(ret)
                break;
            case 2:
                if(!props.resetMessage)
                    return;
                props.resetMessage(typeAction);
                setCreationModalOpen(false);
                break;
            case 3:
                if(!props.resetMessage)
                    return;
                props.resetMessage(typeAction);
                break;
            default:
                break;
        }
    }

    const handleChangeBuilding = (event: React.SyntheticEvent, newValue: number) => {
        setOrigin(0);
        handleSetLote(newValue);
        setBuilding(newValue);
    };

    const handleChangeOrigin = (event: React.SyntheticEvent, newValue: number) => {
        setOrigin(newValue);
    };

    const handleChangeDataHeader = (id:string, evt:any, type?:string) => {

        let data = cloneDeep(c_report);
        
        if(!type)
            data.journalTable[0][id] = evt.target.value;
        else {
            let txt = ValidateText(type, evt.target.value, false)

            if(txt[0])
                data.journalTable[0][id] = txt[1];
        }

        setReport(data);
    }

    const handleChangeData = (idx: number, id:string, evt:any, type?:string) => {

        let data = cloneDeep(c_report);
        
        if(!type)
            data.journalTable[0].farmJournalLines[building+1][idx][id] = evt.target.value;
        else {
            let txt = ValidateText(type, evt.target.value, false)

            if(txt[0])
                data.journalTable[0].farmJournalLines[building+1][idx][id] = txt[1];
        }

        setReport(data);
    }

    const handleChangeDate = (id:string, evt:any) => {
        let data = cloneDeep(c_report);

        data.journalTable[0][id] = evt
        setReport(data);
    }

    const shouldDisableToday = (date:any) => {
        return false;
    }

    const CreateReport = () => {
        if(c_report.journalTable[0].journalDateTime == null) {
            setInputText("¡ATENCIÓN! Debe indicarse una fecha de parte.");
            setCreationModalOpen(true);
            setModalType(1);
            return;
        } 

        /** Pass all data to lines */
        const tmp_data:Array<any> = [];

        Object.keys(c_report.journalTable[0].farmJournalLines).map((line:any, idx00:number)=>{
            c_report.journalTable[0].farmJournalLines[line].map((item:any, idx:number)=>{
                tmp_data.unshift(item);
            })
        })

        /** Filtra las líneas no respondidas */
        const anyData = tmp_data.filter(md => 
            props.questions[1].every((fd:any) => md[fd.id] === null || md[fd.id].length === 0));

        // /** Compara si se ha rellenado por lo menos una línea */
        if(anyData.length == tmp_data.length) {
            setInputText("¡ATENCIÓN! Debe rellenar el parte como mínimo de una nave.");
            setCreationModalOpen(true);
            setModalType(1);
            return;
        }

        /** Devuelve la línea que por lo menos tiene una respuesta */
        const results = tmp_data.filter(({ lineNumber: lineNumber00,  }) => !anyData.some(({ lineNumber: lineNumber01 }) => lineNumber00 === lineNumber01));
        

        //** First validates format field */
            //** Header */
        let pass_header = true;
        let question_header:any = undefined;
        props.questions[0].map((t_question:any, idx01:number)=>{
            // Si es opcional y no tiene valor, pasa
            if(!t_question.required && (c_report.journalTable[0][t_question.id] === null || c_report.journalTable[0][t_question.id].length == 0))
                return;


            if(c_report.journalTable[0][t_question.id] === null || c_report.journalTable[0][t_question.id].length == 0) {
                if(!question_header)
                    question_header = t_question;
            } else if(["int","float","double"].includes(t_question.inputType) && !ValidateText(t_question.inputType, c_report.journalTable[0][t_question.id], true)[0]) {
                if(!question_header)
                    question_header = t_question;
            }
        })

        if(question_header) {
            setInputText(`¡ATENCIÓN! Debe rellenar el campo "${question_header.name}" de la cabecera del parte con un valor válido.`);
            setCreationModalOpen(true);
            setModalType(1);
            pass_header = false;
            return;
        }       

        let pass_lines = true;
        if(pass_header)     
            //** Lines */
            results.map((item:any, index00:number)=>{
                // Object.keys(item).forEach(k => (!item[k] && item[k] !== undefined) && delete item[k]);

                let question_line:any = undefined;
                props.questions[1].map((t_question:any, idx01:number)=>{
                    // Si es opcional y no tiene valor, pasa
                    if(!t_question.required && (item[t_question.id] === null || item[t_question.id].length == 0))
                        return;


                    if(item[t_question.id] === null || item[t_question.id].length == 0) {
                        if(!question_line)
                            question_line = t_question;
                    } else if(["int","float","double"].includes(t_question.inputType) && !ValidateText(t_question.inputType, item[t_question.id], true)[0]) {
                        if(!question_line)
                            question_line = t_question;
                    }
                })

                if(question_line) {
                    setInputText(`¡ATENCIÓN! Debe rellenar el campo "${question_line.name}" de la nave ${item.buildingId} y del orígen ${item.itemId} con un valor válido.`);
                    setCreationModalOpen(true);
                    setModalType(1);
                    pass_lines = false;
                    return;
                }            
            })

        /** Validate lines questions */
        const requiredHeaderQuestions = filter(props.questions[0], function(o) {return o.required});

        /** Validate lines questions */
        const requiredLinesQuestions = filter(props.questions[1], function(o) {return o.required});
        // const unrequiredQuestions = filter(props.questions, function(o) {return !o.required});

        if(pass_header && pass_lines && lote) {
            let rr = cloneDeep(c_report);

            
            // Change all values from select field to value until correct with relations
            //** Header */
            props.questions[0].map((item:any, idx01:number)=>{
                if(item.type == 1) {
                    const idx = findIndex(item.a_template, function(o:any){return o.id == rr.journalTable[0][item.id]});
                    if(idx == -1)
                        return;
                    rr.journalTable[0][item.id] = item.a_template[idx].value;
                }
            })

            //** Lines */
            let dale = cloneDeep(results);
            dale.map((line:any,  idx00:number)=>{
                props.questions[1].map((item:any, idx01:number)=>{
                    if(item.type == 1) {
                        console.log(item)
                        const idx = findIndex(item.a_template, function(o:any){return o.id == line[item.id]});
                        if(idx == -1)
                            return;

                        line[item.id] = item.a_template[idx].value;
                    }
                    line.lineNumber = (idx00 + 1);
                })
            })

            // Clean values null or empty
            const cleaned = dale.map((item:any,  idx00:number)=>{
                return Object.fromEntries(Object.entries(item).filter(([_, v]) => v != null));
            })
            
            
            rr.journalTable[0].farmJournalLines = cleaned;
            rr.journalTable[0].farmBatchId = lote.id;

            // rr.journalTable[0].farmJournalLines.map((item:any, idx:number)=>{
            //     let o = Object.fromEntries(Object.entries(item).filter(([_, v]) => v != null));
            //     console.log(o)
            // })


            console.log(rr)

            setRet(rr);
            setInputText(`¡ATENCIÓN! ¿Quiere finalizar el parte?`);
            setCreationModalOpen(true);
            setModalType(2);
            setTypeAction(1);
        }
    }

    const GetOrigins = (lote:IBatch) => {
        if(lote.animals.length == 0)
            return undefined;

        const ret = lote.animals.map((item:IOrigin, idx00:number)=>{
            return(
                <Tab label={`${item.itemId} - ${item.itemName}`} />
            )
        });

        return ret;
    }

    const GetWeights = (weights:Array<number>) => {
        let data = cloneDeep(c_report);
        let ret = null;

        if(weights.length >= 2) {
            const min = Math.min(...weights);
            const max = Math.max(...weights);
    
            const diff = max - min;
    
            if (diff > 300) {
                ret = 1;
            } else if (diff >= 250 && diff <= 300) {
                ret = 2;
            } else if (diff >= 200 && diff <= 250) {
                ret = 3;
            } else if (diff >= 150 && diff <= 200) {
                ret = 4;
            } else if (diff < 150) {
                ret = 5;
            }

            console.log(diff)

        }

        data.journalTable[0].farmJournalLines[building+1][origin]["Homogeneity"] = ret;
        data.journalTable[0].farmJournalLines[building+1][origin]["Weights"] = weights;
        setReport(data);
        console.log(ret)

        setHomogeneity(false);
    }

    const handleCalcHomog=(evt:any)=>{
        console.log('dale')
        setHomogeneity(true)
    }

    const VerifyField = (answer:any, question:any) => {
        // let answer = null;

        // if(header)
        //     answer = c_report.journalTable[0][question.id];
        // else
        //     answer = c_report.journalTable[0].farmJournalLines[building+1][origin][question.id];
        // // if(!question.required)
        // //     return [true, ""];


        if(question.required && (answer === null || answer.length == 0))
            return [false, "Debe rellenar este campo"];

        if(question.required || (!question.required && answer !== null && answer.length > 0))
        switch(question.inputType) {
            case "int":
                if(!ValidateText(question.inputType, answer, true)[0])
                    return [false, "El campo debe ser un entero"];
                break;
            case "float":
                if(!ValidateText(question.inputType, answer, true)[0])
                    return [false, "El campo debe ser un entero o decimal"];
                break;
        }

        return [true, ""]
    }

    const f_report_header = (questions:any) => {

        let dale = questions.map((question:any, index:number)=>{
            const verified_field:any = VerifyField(c_report.journalTable[0][question.id], question);
            switch(question.type){
                case 1:
                    return(
                        <SelectorField
                            key={index}
                            name={question.name}
                            id={question.id}
                            fieldsData={question.a_template}
                            handleFieldChange={(id:string, evt:any)=>handleChangeDataHeader(id, evt)}
                            // disabled={[50, 97, 98, 99].indexOf(item.estado) == -1?(n.group.indexOf(myRole) == -1?true:false):true}
                            required={question.required}
                            style={1}
                            //onClick={(question.id == "Homogeneity"?(evt:any)=>handleCalcHomog(evt):undefined)}
                            deviceData={c_report.journalTable[0]}
                            className="dialog-item-25 color-sky-blue"
                        />
                    )
                case 2:
                    return(
                        <TextFieldInput
                            key={index}
                            name={question.name}
                            id={question.id}
                            fieldsData={c_report.journalTable[0]}
                            handleFieldChange={(id:string, evt:any, type:string)=>handleChangeDataHeader(id, evt, type)}
                            // disabled={[50, 97, 98, 99].indexOf(item.estado) == -1?(n.group.indexOf(myRole) == -1?true:false):true}
                            size="medium"
                            shrink={true}
                            disabled={false}
                            type={question.inputType}
                            required={question.required}
                            style={1}
                            helper={verified_field[1]}
                            maxText={question.maxText}
                            noError={verified_field[0]}
                            multiline={question.multiline}
                            rows={3}
                            className="color-sky-blue"
                        />
                    )
                // case 3:
                //     return(
                //         <div key={index} style={{marginBottom:20, display:'inline-block', marginRight:20}}>
                //             {DatePicker(                                    
                //                 n.id,
                //                 n.name,
                //                 "dd/MM/yyyy",
                //                 this.handleChangeDate,
                //                 item[n.id],
                //                 [50, 97, 98, 99].indexOf(item.estado) == -1?(n.group.indexOf(myRole) == -1?true:false):true,
                //                 this.shouldDisableToday,
                //                 new Date('1970-01-01'), undefined, 'color-sky-blue datepicker-100')
                //             }
                //         </div>
                //     );
                default:
                    return('')
            }
        });

        return dale;
    }

    const f_report_lines = (questions:any) => {

        let dale = questions.map((question:any, index:number)=>{
            const verified_field:any = VerifyField(c_report.journalTable[0].farmJournalLines[building+1][origin][question.id], question);
            switch(question.type){
                case 1:
                    return(
                        <SelectorField
                            key={index}
                            name={question.name}
                            id={question.id}
                            fieldsData={question.a_template}
                            handleFieldChange={(id:string, evt:any)=>handleChangeData(origin, id, evt)}
                            // disabled={[50, 97, 98, 99].indexOf(item.estado) == -1?(n.group.indexOf(myRole) == -1?true:false):true}
                            required={question.required}
                            style={1}
                            //onClick={(question.id == "Homogeneity"?(evt:any)=>handleCalcHomog(evt):undefined)}
                            deviceData={c_report.journalTable[0].farmJournalLines[building+1][origin]}
                            className="dialog-item-25 color-sky-blue"
                        />
                    )
                case 2:
                    return(
                        <TextFieldInput
                            key={index}
                            name={question.name}
                            id={question.id}
                            fieldsData={c_report.journalTable[0].farmJournalLines[building+1][origin]}
                            handleFieldChange={(id:string, evt:any, type:string)=>handleChangeData(origin, id, evt, type)}
                            // disabled={[50, 97, 98, 99].indexOf(item.estado) == -1?(n.group.indexOf(myRole) == -1?true:false):true}
                            size="medium"
                            shrink={true}
                            disabled={false}
                            type={question.inputType}
                            required={question.required}
                            style={1}
                            helper={verified_field[1]}
                            maxText={question.maxText}
                            noError={verified_field[0]}
                            multiline={question.multiline}
                            rows={3}
                            className="color-sky-blue"
                        />
                    )
                case 10:
                    return(
                        <TextFieldInput
                            key={index}
                            name={question.name}
                            id={question.id}
                            fieldsData={c_report.journalTable[0].farmJournalLines[building+1][origin]}
                            handleFieldChange={(id:string, evt:any, type:string)=>handleChangeData(origin, id, evt, type)}
                            // disabled={[50, 97, 98, 99].indexOf(item.estado) == -1?(n.group.indexOf(myRole) == -1?true:false):true}
                            size="medium"
                            shrink={c_report.journalTable[0].farmJournalLines[building+1][origin][question.id]?true:false}
                            disabled={false}
                            type={question.inputType}
                            required={question.required}
                            style={1}
                            helper={""}
                            maxText={100}
                            noError={true}
                            multiline={false}
                            className="color-sky-blue"
                            onClick={(evt:any)=>handleCalcHomog(evt)}
                        />
                        // <SelectorField
                        //     key={index}
                        //     name={question.name}
                        //     id={question.id}
                        //     fieldsData={question.a_template}
                        //     handleFieldChange={(id:string, evt:any)=>handleChangeData(origin, id, evt)}
                        //     // disabled={[50, 97, 98, 99].indexOf(item.estado) == -1?(n.group.indexOf(myRole) == -1?true:false):true}
                        //     required={question.required}
                        //     style={1}
                        //     onClick={(evt:any)=>handleCalcHomog(evt)}
                        //     deviceData={c_report.journalTable[0].farmJournalLines[building+1][origin]}
                        //     className="dialog-item-25 color-sky-blue"
                        // />
                    )
                // case 3:
                //     return(
                //         <div key={index} style={{marginBottom:20, display:'inline-block', marginRight:20}}>
                //             {DatePicker(                                    
                //                 n.id,
                //                 n.name,
                //                 "dd/MM/yyyy",
                //                 this.handleChangeDate,
                //                 item[n.id],
                //                 [50, 97, 98, 99].indexOf(item.estado) == -1?(n.group.indexOf(myRole) == -1?true:false):true,
                //                 this.shouldDisableToday,
                //                 new Date('1970-01-01'), undefined, 'color-sky-blue datepicker-100')
                //             }
                //         </div>
                //     );
                default:
                    return('')
            }
        });

        return dale;
    }

    return (
        <React.Fragment>
            <Dialog fullScreen={false} open={true} aria-labelledby="form-dialog-title" className='dialog-little'>
                <DialogTitle id="form-dialog-title">
                    {/* {`${GetTitle(item.type)}`}   */}
                    {`${item.description}`}
                    <p>
                        <a>{`${props.farm.id} - ${props.farm.description}`}</a>
                    </p>            
                    
                    {props.farm.building.length == 0?
                        <a style={{ fontWeight:'normal' }}>{`No hay ninguna nave configurada para esta granja.`}</a>
                    :
                        <React.Fragment>
                            <div className='dialog-item-25'>
                                {DatePicker("journalDateTime", "Fecha parte", "dd/MM/yyyy", handleChangeDate, c_report.journalTable[0].journalDateTime, false, shouldDisableToday, new Date('1970-01-01'))}
                            </div>       

                            <form key={0} noValidate autoComplete="off" style={{overflowX:'hidden', paddingTop:10, display:'flex', flexWrap:'wrap', gap:20}}>
                                {f_report_header(props.questions[0])}
                            </form>

                            <Box sx={{ maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper', marginTop: 3 }}>
                                <Tabs
                                    value={building}
                                    onChange={handleChangeBuilding}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                >
                                    {props.farm.building.map((line:IBuilding, idx:number)=>{
                                        return(
                                            <Tab label={`N - ${line.buildingId}`} />
                                        )
                                    })} 
                                </Tabs>
                            </Box>
                        </React.Fragment>
                    }
                </DialogTitle>
                <DialogContent>
                        {props.farm.building.length == 0?
                            null
                        :
                            lote?
                                <React.Fragment>
                                    <TextFieldInput
                                        key={0}
                                        name={"Lote"}
                                        id={"batchId"}
                                        fieldsData={{ batchId: lote.id }}
                                        handleFieldChange={(id:string, evt:any, type:string)=>handleChangeData(building, id, evt, type)}
                                        // disabled={[50, 97, 98, 99].indexOf(item.estado) == -1?(n.group.indexOf(myRole) == -1?true:false):true}
                                        disabled
                                        size="medium"
                                        shrink={true}
                                        helper={""}
                                        maxText={4000}
                                        type={"text"}
                                        required={true}
                                        style={1}
                                        //noError={n.required?this.verifyForm(0,n.id):true}
                                        noError={true}
                                        rows={3}
                                        className="color-sky-blue"
                                    />

                                    {lote.animals.length > 0?
                                        <React.Fragment>
                                            <Box sx={{ maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper', marginTop: 3 }}>
                                                <Tabs
                                                    value={origin}
                                                    onChange={handleChangeOrigin}
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                    aria-label="scrollable auto tabs example"
                                                >
                                                    {GetOrigins(lote)} 
                                                </Tabs>
                                            </Box>

                                            <form key={0} noValidate autoComplete="off" style={{overflowX:'hidden', paddingTop:10, display:'flex', flexWrap:'wrap', gap:20}}>
                                                {f_report_lines(props.questions[1])}
                                            </form>
                                        </React.Fragment>
                                    :
                                        <p>
                                            {`No hay ningún orígen para este lote y nave.`}
                                        </p>
                                    }
                                </React.Fragment>
                            :
                                `No hay ningún lote activo para esta nave.`}

                </DialogContent>
                <DialogActions>
                    {props.farm.building.length == 0?
                        <div style={{ display:'flex', gap:20 }}>
                        <Button onClick={()=>props.handleClose()} className="cancel-dialog">
                            {'Ok'}
                        </Button>
                        </div>
                    :
                        <div style={{ display:'flex', gap:20 }}>
                            <Button onClick={()=>props.handleClose()} className="cancel-dialog">
                                {'Cancelar'}
                            </Button>
                            <Button onClick={()=>CreateReport()} className="confirm-dialog">
                                {'Crear'}
                            </Button>
                        </div>
                    }
                </DialogActions>
            </Dialog>

            <CreateModal
                onCloseCreationModal={()=>setCreationModalOpen(false)}
                isOpen={creationModalOpen}
                inputText={inputText}
                typeModal={modalType}
                action={handleCreateModal}
                typeAction={typeAction}
                loadingModal={props.saving}
            />

            {openHomogeneity?
                <Homogeneity
                    handleClose={()=>setHomogeneity(false)}
                    handleRetHomogeneity={GetWeights}
                    unit={"gramos (gr)"}
                    disabled={false}
                    c_weights={c_report.journalTable[0].farmJournalLines[building+1][origin]["Weights"]}
                />
            :
                null
            }
        </React.Fragment>
    );
};

export default CreateReport;