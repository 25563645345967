import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { IMe } from '../Library/types';
import logoVC from '../Assets/images/logo_color_vc.png';

const styles = (theme: Theme) => createStyles({
    containerStyle: {
        textAlign: 'center',
        verticalAlign: 'middle',
        color: '#888',
        lineHeight: '1.2',
        marginTop: '40vh',
    },
    titleStyle: {
        color: '#555',
        fontSize: '2em',
        fontWeight: 'bold',
    },
    bodyStyle: {
        margin: '0 auto',
        width: '280px',
    },
    imageStyle: {
        width: 200,
    }
});

interface IOutOfServiceProps {
    //history: any;
    classes: any;
    refreshMyInfo: Function;
    myInfo?: IMe;
}

interface IOutOfServiceState {
}

let timer : any;

class OutOfService extends Component<IOutOfServiceProps, IOutOfServiceState> {
    
    componentDidMount() {
        //timer = setInterval(this.checkDisabled, 10000);
    }
    goBack = () => {
        this.props.refreshMyInfo();
        //this.props.history.push('/');
    }
    checkDisabled=()=>{
        if(this.props.myInfo !== undefined && this.props.myInfo.activated == true){
            clearInterval(timer)
            //this.props.history.push('/');
        }
    }

    setMessage=()=>{
        const { classes } = this.props;

        if(this.props.myInfo!==undefined && this.props.myInfo.userId == 'jcastillo@vallcompanys.es'){
            return(
                <p className={classes.bodyStyle}>{`Javi, SOLICITA permiso para acceder...`}</p>
            )
        }
    }
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.containerStyle}>
                <img className={classes.imageStyle} src={logoVC} alt='logo' />
                <div className={classes.titleStyle}>{'EN MANTENIMIENTO'}</div>
                {this.setMessage()}
                <br />
            </div>
        );
    }
}

export default withStyles(styles)(OutOfService);