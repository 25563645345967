import React, { Component } from 'react';
import VirtualizedList from './Material/VirtualizedList';
import { IFarm, IMe, IFilter, IReport, IIReport } from '../Library/types';
import SearchField from './Material/SearchField';
import Loader from './Material/Loader';
import {searchFarms} from '../Library/helpers'
import FarmListItem from './FarmListItem';
import ReportListItem from './ReportListItem';

import OrderService from '../ApiClients/OrderService';
import { withParamsAndNavigate } from './Material/ReactRouter';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import ClearAllIcon from '@material-ui/icons/LayersClearOutlined';
import { SelectorField, CheckBoxField, TextFieldInput } from './FormsWidgets';
import {filter, includes, cloneDeep, groupBy} from 'lodash';
import Layout from './Layout';
import './Listado.css';
import Footer from './Footer';
import { reports } from '../ApiClients/DummyData';
import AvailableReports from "./ReportsAvailable";
import CreateModal from './Material/CreateDialog'
import CreateReport from "./Report_00_disabled";

const dummyFarm:IFarm = {
    "owner": "",
    "address": "LA BURQUERA S/N\n43460 ALCOVER\nESP",
    "postalCode": "43460",
    "town": "Alcover",
    "country": "ESP",
    "phone": "",
    "email": "",
    "ownerNif": "50416579G",
    "holderNif": "A25249061",
    "legalCapacity": 0,
    "realCapacity": 0,
    "veterinaryId": "",
    "building": [
        {
            "buildingId": "1",
            "name": "",
            "area": 0,
            "unitSize": "m2",
            "batch": [
                {
                    "id": 1,
                    "status": 0,
                    "active": true,
                    "openedDate": "2023-08-24T12:00:00",
                    "closedDate": "1900-01-01T12:00:00",
                    "continues": false,
                    "openAnimals": 0,
                    "actualAnimals": 0,
                    "animals": [
                        {
                            "itemId": "370",
                            "itemName": "GALLINAS PUESTA",
                            "unit": "kg",
                            "quantity": -317
                        },
                        {
                            "itemId": "371",
                            "itemName": "GALLOS PUESTA",
                            "unit": "kg",
                            "quantity": 5293
                        }
                    ],
                    "feeds": []
                },
                {
                    "id": 1,
                    "status": 0,
                    "active": false,
                    "openedDate": "2023-10-25T12:00:00",
                    "closedDate": "1900-01-01T12:00:00",
                    "continues": false,
                    "openAnimals": 0,
                    "actualAnimals": 0,
                    "animals": [
                        {
                            "itemId": "370",
                            "itemName": "GALLINAS PUESTA",
                            "unit": "kg",
                            "quantity": 6120
                        },
                        {
                            "itemId": "371",
                            "itemName": "GALLOS PUESTA",
                            "unit": "kg",
                            "quantity": 6558
                        }
                    ],
                    "feeds": []
                },
                {
                    "id": 1,
                    "status": 0,
                    "active": false,
                    "openedDate": "2023-11-01T12:00:00",
                    "closedDate": "1900-01-01T12:00:00",
                    "continues": false,
                    "openAnimals": 0,
                    "actualAnimals": 0,
                    "animals": [
                        {
                            "itemId": "370",
                            "itemName": "GALLINAS PUESTA",
                            "unit": "kg",
                            "quantity": 4900
                        },
                        {
                            "itemId": "371",
                            "itemName": "GALLOS PUESTA",
                            "unit": "kg",
                            "quantity": 663
                        }
                    ],
                    "feeds": []
                },
                {
                    "id": 2,
                    "status": 0,
                    "active": false,
                    "openedDate": "2023-11-20T12:00:00",
                    "closedDate": "1900-01-01T12:00:00",
                    "continues": false,
                    "openAnimals": 0,
                    "actualAnimals": 0,
                    "animals": [],
                    "feeds": []
                }
            ]
        },
        {
            "buildingId": "2",
            "name": "",
            "area": 0,
            "unitSize": "m2",
            "batch": [
                {
                    "id": 1,
                    "status": 0,
                    "active": true,
                    "openedDate": "2023-11-01T12:00:00",
                    "closedDate": "1900-01-01T12:00:00",
                    "continues": false,
                    "openAnimals": 0,
                    "actualAnimals": 0,
                    "animals": [
                      
                    ],
                    "feeds": []
                },
                {
                    "id": 2,
                    "status": 0,
                    "active": false,
                    "openedDate": "2023-11-20T12:00:00",
                    "closedDate": "1900-01-01T12:00:00",
                    "continues": false,
                    "openAnimals": 0,
                    "actualAnimals": 0,
                    "animals": [],
                    "feeds": []
                }
            ]
        },
        {
            "buildingId": "3",
            "name": "",
            "area": 0,
            "unitSize": "m2",
            "batch": []
        },
        {
            "buildingId": "4",
            "name": "",
            "area": 0,
            "unitSize": "m2",
            "batch": []
        },
        {
            "buildingId": "5",
            "name": "",
            "area": 0,
            "unitSize": "m2",
            "batch": []
        },
        {
            "buildingId": "6",
            "name": "",
            "area": 0,
            "unitSize": "m2",
            "batch": []
        },
        {
            "buildingId": "7",
            "name": "",
            "area": 0,
            "unitSize": "m2",
            "batch": [
                {
                    "id": 1,
                    "status": 0,
                    "active": true,
                    "openedDate": "2023-08-24T12:00:00",
                    "closedDate": "1900-01-01T12:00:00",
                    "continues": false,
                    "openAnimals": 0,
                    "actualAnimals": 0,
                    "animals": [
                        {
                            "itemId": "370",
                            "itemName": "GALLINAS PUESTA",
                            "unit": "kg",
                            "quantity": 238
                        },
                        {
                            "itemId": "371",
                            "itemName": "GALLOS PUESTA",
                            "unit": "kg",
                            "quantity": 2168
                        }
                    ],
                    "feeds": []
                }
            ]
        },
        {
            "buildingId": "8",
            "name": "",
            "area": 0,
            "unitSize": "m2",
            "batch": []
        }
    ],
    "id": "AVRTEST1",
    "business": "TAVC",
    "description": "AVRTEST1",
    "sector": 1,
    "latitude": 0,
    "longitude": 0,
    "licensePlate": "test1",
    "regaId": "espec"
}

interface IFarmsProps {
    myInfo?: IMe;
    version: string;
    params?:any;
    navigate?:any;
    filters: IFilter;
    handleChangeFilter: Function;
}

interface IFarmsState {
    reports: Array<IIReport>;
    report?:any;
    searchValue: string;
    filteredFarms: Array<IFarm>;
    loading: boolean;
    filter_id: string;
    options:Array<IFarm>;

    valid: boolean;
    msg: string;

    availableReports: Array<IReport>;
    loadingAvailableReports: boolean;
    openAvailableReports: boolean;

    openCreateReport: boolean;
    itemCreateReport?: IReport;

    modalType: number;
    typeAction: number;
    inputText: string;
    creationModalOpen: boolean;

    loadBeauty: boolean;

    loadingModal: boolean;
}

class Reports extends Component<IFarmsProps, IFarmsState> {
    constructor(props: IFarmsProps) {
        super(props);
        this.state = {
            reports: [],
            filteredFarms:[],            
            searchValue: '',
            filter_id: 'farms',
            loading: true,
            options:[],
            valid: false,
            msg: ""  ,
            availableReports: [],
            loadingAvailableReports: true,
            openAvailableReports: false,
            openCreateReport: false,

            modalType: -1,
            typeAction: -1,
            inputText:'',
            creationModalOpen: false,

            loadBeauty: true,
            loadingModal: false
        };
    }

    componentDidMount() {
        this.getFarms();
    }

    resetLocalStorage = () => {
        localStorage.setItem('searchValueProduct', '')
    }

    handleCreateModal = (typeAction: number) => {
        switch(typeAction) {
            case 1:
                break;
            default:
                break;
        }
    }

    getFarms = async () =>{
        let response = await OrderService.getReports();        

        if(response.valid){
            this.setState({
                valid: true,
                filteredFarms: searchFarms(response.data, this.state.searchValue),
                reports: response.data,
                // availableReports: reports,
                loading:false,
                loadBeauty: false,
                loadingAvailableReports: false
            })
            /*
            if(response.length === 1) {
                this.resetLocalStorage();
                this.props.history.push(`/`)
                this.props.history.push(`/granja/${response[0].dataareaid}/${response[0].id}/productos`)
            } else{
                this.setState({
                    filteredFarms: searchFarms(response, this.state.searchValue),
                    farms: response,
                    loading:false
                })
            }*/            
        } else {
            this.setState({
                valid: false,
                msg: response.msg,
                loading: false,
                loadBeauty: false,
                // reports: reports,
                // availableReports: reports,
                loadingAvailableReports: false
            })
        }
    }

    getReport = async (business:string, reportId:string) =>{
        let response = await OrderService.getReport(business, reportId);        
        if(response.valid){
            this.setState({
                valid: true,
                report: response.data[0],
                loadBeauty: false,
                creationModalOpen: false,
                loading: false,
                openCreateReport: true,
                loadingModal: false
            })
            /*
            if(response.length === 1) {
                this.resetLocalStorage();
                this.props.history.push(`/`)
                this.props.history.push(`/granja/${response[0].dataareaid}/${response[0].id}/productos`)
            } else{
                this.setState({
                    filteredFarms: searchFarms(response, this.state.searchValue),
                    farms: response,
                    loading:false
                })
            }*/            
        } else {
            this.setState({
                valid: false,
                inputText: response.msg,
                loadBeauty: false,
                creationModalOpen: true,
                modalType: 1,
                loading: false,
                loadingModal: false
                // reports: reports,
            })
        }
    }

    renderFarms=(farms:Array<IIReport>)=>{
        console.log(farms)
        if(farms.length == 0)
            return (
                <div style={{textAlign:'center', padding:50}}>
                    <p>
                        {`No tiene partes para visualizar.`}
                    </p>
                </div>
            )
        let items = farms.map((farm) => {
            return {
                ...farm,
                onItemClick: () => this.handleItemClick(farm),
            }
        })

        console.log(items)
        return(
            <VirtualizedList items={items} RowComponent={ReportListItem} itemSize={95} />
        );
    }

    handleCleanFilters = (evt:any)=>{
        evt.stopPropagation();
        this.props.handleChangeFilter(this.state.filter_id, {}, false);
    }

    handleChangeFilter=(id:string, evt:any)=>{
        let newFilters = cloneDeep(this.props.filters.farms);
        newFilters[id] = evt.target.value;
        this.props.handleChangeFilter(this.state.filter_id, newFilters, false);
    }

    RenderFilters = (documents:Array<IFarm>, arrayfilters:any) => {
        const grouped_sectordsc = groupBy(documents, function(register) {
            return register.sector;
        });

        const merged_sectordsc = Object.keys(grouped_sectordsc).map((name:string, idx:number)=>{
            return {
                id: name,
                name: name
            }
        });

        const grouped_dataareaid = groupBy(documents, function(register) {
            return register.business;
        });

        const merged_dataareaid = Object.keys(grouped_dataareaid).map((name:string, idx:number)=>{
            return {
                id: name,
                name: name
            }
        });


        const data:any = {
            sectorDesc: merged_sectordsc,
            areaID: merged_dataareaid,
        }

        let filters = this.state.reports.map((filter:any, index:number)=>{

            return (
                <SelectorField
                    key={index}
                    name={filter.name}
                    id={filter.id}
                    deviceData={arrayfilters}
                    fieldsData={data[filter.id]?data[filter.id]:[]}
                    handleFieldChange={this.handleChangeFilter}
                    required={filter.required}
                    style={filter.style}
                    className='filter-item-200px'
                />
            );
        })

        return filters;
    }
    
    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false });
    }

    handleItemClick = (farm: IIReport) => { 
        this.setState({
            inputText: `Cargando parte ${farm.farmJournalId}...`,
            creationModalOpen: true,
            loadBeauty: true,
            loadingModal: true,
        }, ()=>this.getReport(farm.business, farm.farmJournalId))     
        
        //this.props.navigate(`/report/vc/${farm.farmJournalId}`)
    }

    handleInfoClick = (farm: IIReport) => {
        // this.props.navigate(`/report/create/${farm.id}`)
    }

    
    handleFilterFarms=(farms:Array<IIReport>)=>{
        let newDocuments = cloneDeep(farms);

        //** FILTRA POR EL VALOR DE BÚSQUEDA */
        const searchValue = this.state.searchValue.toLowerCase();

        if(newDocuments.length > 0 && searchValue && searchValue.length > 0){
            newDocuments = filter(newDocuments, function(o){return includes(o.farmJournalId.toLowerCase(), searchValue) });
        }
        return newDocuments;
    }


    handleValueChange = (evt: any) => {
        if(this.state.reports !== undefined) {
            this.setState({ 
                searchValue: evt.target.value,
             })
        }
    }

    handleFormAvailableReport = (state:boolean) => {
        this.setState({
            openAvailableReports: state
        })
    }

    handleClickAvailableReport = (report:IReport) => {
        // this.props.navigate(`/report/create/${report.id}`)
        this.setState({
            openCreateReport: true,
            itemCreateReport: report,
            openAvailableReports: false
        })
    }

    handleFormCreateReport = (state:boolean) => {
        this.setState({
            openCreateReport: state,
        })
    }

    handleCreateReport = (report: IReport) => {
        console.log(report)
        this.setState({
            openCreateReport: false,
            itemCreateReport: undefined
        })
    }

    render() {
        const {options, filter_id, report, reports, loadingAvailableReports, availableReports, openAvailableReports, openCreateReport, itemCreateReport} = this.state;
        const {myInfo, filters} = this.props;
        const apply_filters: any = filters[filter_id];

        const f_farms = this.handleFilterFarms(reports);

        console.log(reports)
        
        return (
            <div>
                <Layout 
                    showLateralMenu={true}
                    version={this.props.version}
                    me={this.props.myInfo}
                    title={'Listado de partes'}
                    navigate={this.props.navigate}
                    loadbeauty={this.state.loadBeauty}
                />
                {!this.state.loading?
                    this.state.valid?
                        <div style={{ maxHeight: 'calc(100% - 112px)', overflowY:'auto' }}>
                            <div className='page-container'>
                                <div className='search-container'>
                                    {/* <IconButton onClick={()=>this.props.navigate(-1)}>
                                        <BackIcon style={{fill:'#6d6d6d'}} />
                                    </IconButton> */}
                                    <SearchField 
                                        allowFilter 
                                        allowSearch={!this.state.loading} 
                                        value={this.state.searchValue} 
                                        onValueChange={this.handleValueChange} 
                                        placeholder="Buscar parte..." />
                                </div>
                            
                            </div>
                            
                            {/* <div className="App-header-options" style={{ marginRight:20 }}>
                                <div style={{ display:'flex', gap:'16px' }}>
                                    <div className={`App-header-options-item App-header-options-item-create ${!loadingAvailableReports?null:`App-header-options-item-disable`}`} onClick={()=>(!loadingAvailableReports?this.handleFormAvailableReport(true):null)}>
                                        {`+ Crear parte`}
                                    </div>
                                </div>
                            </div> */}
                            <div className='listado-container' style={{
                                    position: 'absolute',
                                    // top: '200px',
                                    top:'130px',
                                    left: 0,
                                    right: 0,
                                    bottom: '60px',
                                }}>
                                


                                {this.renderFarms(f_farms)}
                                
                                {/* {openAvailableReports?
                                    <AvailableReports
                                        items={availableReports}
                                        handleClickReport={(item:IReport)=>this.handleClickAvailableReport(item)}
                                        handleClose={()=>this.handleFormAvailableReport(false)}
                                    />
                                :
                                    null
                                } */}

                                {/* {openCreateReport && itemCreateReport?
                                    <CreateReport
                                        item={itemCreateReport}
                                        handleCreateReport={(item:IReport)=>this.handleCreateReport(item)}
                                        handleClose={()=>this.handleFormCreateReport(false)}
                                        farm={dummyFarm}
                                    />
                                :
                                    null
                                } */}
                            </div>
                        </div>
                    :
                        <div style={{
                            position: 'absolute',
                            top: '120px',
                            left: 0,
                            right: 0,
                            bottom: '60px',
                            textAlign: 'center'
                        }}>
                            <p>
                                {`${this.state.msg}`}
                            </p>
                        </div>
                :
                    <div style={{marginLeft:'50%',marginTop:200}}>
                        <Loader loading={true}/>
                    </div>
                }

                {this.state.creationModalOpen?
                    <CreateModal
                        onCloseCreationModal={this.onCloseCreationModal}
                        isOpen={this.state.creationModalOpen}
                        inputText={this.state.inputText}
                        typeModal={this.state.modalType}
                        action={this.handleCreateModal}
                        typeAction={this.state.typeAction}
                        loadingModal={this.state.loadingModal}
                    />
                :
                    null
                }

                {myInfo && report && openCreateReport?
                    <CreateReport
                        report={report}
                        handleClose={()=>this.handleFormCreateReport(false)}
                    />
                :
                    null
                }
                <Footer myInfo={this.props.myInfo} />
            </div>
        );
    }
}
export default withParamsAndNavigate(Reports);