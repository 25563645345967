import React, { Component, useState } from 'react';
import Loader from './Material/Loader';
import {IFarm, IMe } from '../Library/types';
import Layout from './Layout';
import OrderService from '../ApiClients/OrderService';
import { withParamsAndNavigate } from './Material/ReactRouter';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './Farm.css';
import Footer from './Footer';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
interface IFarmProps{
    myInfo?: IMe;
    version: string;
    params?:any;
    navigate?:any;    
}

interface IFarmState{
    loading: boolean;
    farm?:IFarm;
    tabActive: any;    
}

class Farm extends Component<IFarmProps, IFarmState>{
    constructor(props: IFarmProps) {
        super(props);
        this.state = {
            loading: true,
            tabActive: 0,            
        };
    }    

    componentDidMount() {         
        this.getFarm(this.props.params.business,this.props.params.id);
    }

    getFarm = async (business:string, id:string) =>{
        let response = await OrderService.getFarm(business, id);
        this.setState({
            farm: response,                
            loading:false
        })                    
    }

    handleTabChange = (e:any, tabIndex:number) => {
        this.setState({
            tabActive: tabIndex
        });
    };

    farmData = (farm:IFarm) =>{
        return [
            {name: 'Empresa', value: farm.business},
            {name: 'ID', value: farm.id},
            {name: 'Nombre', value: farm.description},
            {name: 'Dirección', value: farm.address},
            {name: 'Código postal', value: farm.postalCode},
            {name: 'Teléfono', value: farm.phone},
            {name: 'Email', value: farm.email},
            {name: 'Capacidad legal', value: farm.legalCapacity}
        ];
    }

    batchData = (farm:IFarm) =>{
        return [
            {name: 'Empresa', value: farm.business},
            {name: 'ID', value: farm.id},
            {name: 'Nombre', value: farm.description},
            {name: 'Dirección', value: farm.address},
            {name: 'Código postal', value: farm.postalCode},
            {name: 'Teléfono', value: farm.phone},
            {name: 'Email', value: farm.email},
            {name: 'Capacidad legal', value: farm.legalCapacity}
        ];
    }

    createProcendenciaLote =(nombre: string, codMadres:string, codDestete:string, altas:number, bajas:number)=>{
        return {nombre, codMadres, codDestete, altas, bajas};
    }

    render() {
        
        const {farm, loading} = this.state;
        // const ProcedenciasLoteActual = this.state.farm?.procedencias.loteActual.informacion === undefined ? new Array() : this.state.farm?.procedencias.loteActual.informacion;
        // const ProcedenciasLoteAnterior = this.state.farm?.procedencias.loteAnterior.informacion === undefined ? new Array() : this.state.farm?.procedencias.loteAnterior.informacion;
        // const PiensoLoteActual = this.state.farm?.pienso.loteActual.informacion === undefined ? new Array() : this.state.farm?.pienso.loteActual.informacion;
        // const PiensoLoteAnterior = this.state.farm?.pienso.loteAnterior.informacion === undefined ? new Array() : this.state.farm?.pienso.loteAnterior.informacion;
        const Lotes = this.state.farm?.batchs === undefined ? new Array() : this.state.farm?.batchs;

        return(
            <div>
                <Layout 
                    showLateralMenu={true}
                    version={this.props.version}
                    me={this.props.myInfo}
                    title={this.state.farm?.description}
                    navigate={this.props.navigate}
                />
                {farm?
                    <div className='page-container' style={{ maxHeight: 'calc(100% - 112px)', overflowY:'auto', paddingTop:'1em' }}>

                        <Box style={{ display:'flex' }} sx={{ borderBottom: 1, borderColor: 'divider' }}>    
                            <IconButton onClick={()=>this.props.navigate(-1)}>
                                <BackIcon style={{fill:'#6d6d6d'}} />
                            </IconButton>
                            <Tabs   value={this.state.tabActive} 
                                    onChange={this.handleTabChange}
                                    variant="scrollable"
                                    scrollButtons='on'
                                    indicatorColor='primary'                                
                                    aria-label="scrollable auto tabs example">
                                    
                                <Tab label='DATOS' />
                                <Tab label='PROCEDENCIA' />
                                <Tab label='PIENSO' />
                                <Tab label='LOTES' />
                                <Tab label='CENSO' />
                                <Tab label='PREVISIÓN' />
                            </Tabs>
                        </Box>
                        {/* TAB 1 DATOS*/}
                        {this.state.tabActive === 0 &&  (
                            <Box sx={{ p: 3 }}>
                                <TableContainer component={Paper}>
                                    <Table className='table-alternate'>
                                        <TableBody>
                                        {this.farmData(farm).map((row:any, index:number) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="left">{row.value}</TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        )}
                    
                        {/* TAB 2 PROCEDENCIAS */}
                        {this.state.tabActive === 1 &&(
                            <Box sx={{ p: 3 }}>
                                {farm.building.map((row:any, index:number) => {
                                    let batchs = row.batch.sort((a:any, b:any) => new Date(a.openedDate).getTime() - new Date(b.openedDate).getTime());

                                    let c_data = batchs.map((item:any, idx00:number)=>{

                                        
                                        let data = item.animals.map((item01:any, idx01:number)=>{
                                            return(
                                                <TableRow key={idx01}>
                                                    <TableCell align="center" component="td" scope="row">
                                                        {item.id}
                                                    </TableCell>
                                                    <TableCell align="center">{item01.itemId}</TableCell>
                                                    <TableCell align="center">{item01.itemName}</TableCell>
                                                    <TableCell align="center">{item01.quantity}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                        if(data.length == 0)
                                            return null;

                                        return data;
                                    })

                                    return (
                                        <React.Fragment>
                                            <div style={{ textAlign:'center' }}>
                                                <p style={{ border: '1px solid #FF972B', borderRadius:'25px' }}>
                                                    {`Nave ${row.buildingId}`}
                                                </p>
                                            </div>
                                            <TableContainer style={{ marginTop:20 }} component={Paper}>
                                                <Table className='table-alternate'>
                                                    <TableHead >
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight:'bold' }} align="center" component="th" scope="row">{`Lote`}</TableCell>
                                                            <TableCell style={{ fontWeight:'bold' }} align="center">{`Código`}</TableCell>
                                                            <TableCell style={{ fontWeight:'bold' }} align="center">{`Descripción`}</TableCell>
                                                            <TableCell style={{ fontWeight:'bold' }} align="center">{`Cantidad`}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {c_data}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </React.Fragment>
                                    );

                                })}
                            </Box>
                        )}
                    
                        {/* TAB 3 PIENSO */}
                        {this.state.tabActive === 2 && (
                            <Box sx={{ p: 3 }}>
                            {/* <p style={{ width:'100%', textAlign:'center' }}>
                                Total Teórico:<span>{this.state.farm?.pienso.loteActual.altas}Kg</span> - Total Real:<span>{this.state.farm?.pienso.loteActual.bajas}Kg</span>
                            </p> */}
                            {farm.building.map((row:any, index:number) => {
                                let batchs = row.batch.sort((a:any, b:any) => new Date(a.openedDate).getTime() - new Date(b.openedDate).getTime());

                                let c_data = batchs.map((item:any, idx00:number)=>{

                                    
                                    let data = item.feeds.map((item01:any, idx01:number)=>{
                                        return(
                                            <TableRow key={idx01}>
                                                <TableCell align="center" component="td" scope="row">
                                                    {item.id}
                                                </TableCell>
                                                <TableCell align="center">{item01.itemId}</TableCell>
                                                <TableCell align="center">{item01.itemName}</TableCell>
                                                <TableCell align="center">{item01.quantity}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                    if(data.length == 0)
                                        return null;

                                    return data;
                                })

                                return (
                                    <React.Fragment>
                                        <div style={{ textAlign:'center' }}>
                                            <p style={{ border: '1px solid #FF972B', borderRadius:'25px' }}>
                                                {`Nave ${row.buildingId}`}
                                            </p>
                                        </div>
                                        <TableContainer style={{ marginTop:20 }} component={Paper}>
                                            <Table className='table-alternate'>
                                                <TableHead >
                                                    <TableRow>
                                                        <TableCell style={{ fontWeight:'bold' }} align="center" component="th" scope="row">{`Lote`}</TableCell>
                                                        <TableCell style={{ fontWeight:'bold' }} align="center">{`Código`}</TableCell>
                                                        <TableCell style={{ fontWeight:'bold' }} align="center">{`Descripción`}</TableCell>
                                                        <TableCell style={{ fontWeight:'bold' }} align="center">{`Cantidad`}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {c_data}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </React.Fragment>
                                );

                            })}

                        </Box>
                        )}
                    
                        {/* TAB 4 LOTES */}
                        {this.state.tabActive === 3 && (
                            <Box sx={{ p: 3 }}>
                                {farm.building.map((row:any, index:number) => {

                                    let batchs = row.batch.sort((a:any, b:any) => new Date(a.openedDate).getTime() - new Date(b.openedDate).getTime());

                                    let data = batchs.map((item:any, idx00:number)=>{
                                        return(
                                            <TableRow key={idx00}>
                                                <TableCell align="center">{item.id}</TableCell>
                                                <TableCell align="center">{item.status}</TableCell>
                                                <TableCell align="center">{item.openedDate}</TableCell>
                                                <TableCell align="center">{item.closedDate}</TableCell>
                                            </TableRow>
                                        )
                                    })


                                    return (
                                        <React.Fragment>
                                            <div style={{ textAlign:'center' }}>
                                                <p style={{ border: '1px solid #FF972B', borderRadius:'25px' }}>
                                                    {`Nave ${row.buildingId}`}
                                                </p>
                                            </div>
                                            <TableContainer style={{ marginTop:20 }} component={Paper}>
                                                <Table className='table-alternate'>
                                                    <TableHead >
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight:'bold' }} align="center">{`Lote`}</TableCell>
                                                            <TableCell style={{ fontWeight:'bold' }} align="center">{`Estado`}</TableCell>
                                                            <TableCell style={{ fontWeight:'bold' }} align="center">{`Fecha apertura`}</TableCell>
                                                            <TableCell style={{ fontWeight:'bold' }} align="center">{`Fecha cierre`}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {data}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </React.Fragment>
                                    );

                                })}
                            </Box>
                        )}

                        {/* TAB 5 CENSO */}
                        {this.state.tabActive === 4 && (
                            <Box sx={{ p: 3 }}>
                            <p>
                                Datos por agregar
                            </p>
                            </Box>
                        )}

                        {/* TAB 6 PREVISION */}
                        {this.state.tabActive === 5 && (
                            <Box sx={{ p: 3 }}>
                            <p>
                                Datos por agregar
                            </p>
                            </Box>
                        )}
                    </div>
                :
                    !loading? 
                        <div style={{ width:'100%', textAlign:'center', marginTop:'20%' }}>
                            <p style={{ padding:30 }}>
                                {`No hay datos para la granja ${this.props.params.id}, compruebe que exista o tenga permisos para visualizarla.`}
                            </p>
                        </div>
                    :
                        <div style={{ marginTop:'10%', marginLeft:'50%' }}>
                            <Loader loading={true}/>
                        </div>
                }
                <Footer myInfo={this.props.myInfo} />
            </div>
        )
    }
}
export default withParamsAndNavigate(Farm);