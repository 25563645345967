import React from 'react';
import { adalApiFetch } from './adalConfig';
import appData from '../ApiClients/appData';
import { filter, split, reduce, find, cloneDeep, groupBy, findIndex } from 'lodash';
import { IData, IFarm, IMarker, IMe } from './types';
import {GetFormattedDate} from './vc_general';

import {inflateSync} from 'react-zlib-js';
import { Buffer } from 'buffer';

const fetchAPI = (
    url: string,
    method: string,
    parseJSON = false,
    body: any = undefined,
    headers = true,
    signal?:AbortSignal
): Promise<any> => {
    return adalApiFetch(fetch, url, {
        signal: (signal?signal:undefined), 
        method: method,
        mode: 'cors',
        redirect: 'follow',
        body: body ? JSON.stringify(body) : body,
        headers: headers
            ? new Headers({
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json; charset=utf-8',
            })
            : undefined
    }).then((response: any) => {
        if (response.ok) {
            if (parseJSON) {
                return response.json();
            } else {
                return response.text();
            }
        } else if (!parseJSON) {
            throw new Error(`Error ${response.status}`);
        } else {
            try {
                return response.json().then((data: any) => {
                    console.log('Error!');
                    return Promise.reject(new Error(data.message));
                });
            } catch (err) {
                throw new Error(`Error ${response.status}`);
            }
        }
        return response;
    });
}

const zLibInflate=(data:any)=>{
  const buff:any = Buffer.from(data,'base64');

  const output = JSON.parse(inflateSync(buff).toString());

  return output;
}

const searchFarms = (inputData:Array<IFarm>, value: string) => {
  /*let lowerCaseValue = value.toLowerCase();
  let arrayValues : Array<string> = splitData(lowerCaseValue);
  let Farms : Array<IFarm> = inputData;

  arrayValues.forEach(element => {
      Farms = filter(Farms,function(item){
      return item['name'].toLowerCase().indexOf(element)!==-1 || String(item['id']).toLowerCase().indexOf(element)!==-1;
      });
  });*/

  return [];
}

const checkHistorico=(dscode:string, dataType:number, dataDevices?:Array<IData>) =>{
  var result: any = []
  var dates:Array<number> = []
  var values:Array<number|IData> = []
  //const currentArray = appData.getHistoricoValues().filter(word => word.dsCode === dscode);
  //const currentArrayHistogramaPesos = appData.getHistoricoValues().filter(word => word.dsCode.includes(dscode));
  if(dataDevices == undefined)
    return([[],[]]);
  const currentArray = dataDevices.filter(word => word.dsCode === dscode);
  const currentArrayHistogramaPesos = dataDevices.filter(word => word.dsCode.includes(dscode));

  switch(dataType) {
    case 1:
      currentArray.forEach(function(element) {
          dates.push(element.hour)
          values.push(element.TENDVALUE)
      });
      result = [dates,values]
      break;
    
    case 2:
      currentArray.forEach(function(element) {
        dates.push(element.hour)
        values.push(element.ACUMVALUE)
      });
      result = [dates,values]
      break;
    case 3:
      currentArray.forEach(function(element) {
          dates.push(element.hour)
          values.push(element.TENDVALUE)
      });
      result = [dates,values]
      break;
    case 4:
      currentArray.forEach(function(element) {
          dates.push(element.hour)
          values.push(element.ACUMRATIO)
      });
      result = [dates,values]
      break;
  default:
    break;
  }
  return(result)
}
  
const checkOnlineValues=(object:string, dataType:number, dataDevices?:Array<IData>)=>{

  var result:any = ["Sin dato", "Sin fecha"]
  //const data = cloneDeep(appData.getOnlineValues());
  const data = cloneDeep(dataDevices);

  if(data == undefined){
    result = ["Cargando...", "Cargando..."];
    return result;
  }

  data.reverse();

  const currentArray = find(data, function(o){return o.dsCode == object} );

  if(currentArray == undefined)
      return(result);
  switch(dataType) {
      case 1:
          result = [currentArray.TENDVALUE, currentArray.dtRegister]
          break;
      
      case 2:
          result = [currentArray.ACUMVALUE, currentArray.dtRegister]
          break;
      case 3:
        var initialValue = 0;
        var sum = data.filter(word => word.dsCode === object ).reduce(function (accumulator, currentValue) {
            return accumulator + currentValue.TENDVALUE;
        },initialValue)
        result = [parseFloat(sum.toFixed(2))]
        result.push(currentArray.dtRegister)
        break;
      case 4:
          var initialValue = 0;
          var sum = data.filter(word => word.dsCode === object).reduce(function (accumulator, currentValue) {
              return accumulator + currentValue.ACUMRATIO;
          },initialValue)
          result = [parseFloat(sum.toFixed(2))]
          result.push(currentArray.dtRegister)
          result.push(currentArray.numberPigs)
          break;
      default:
      break;
    }

    return result;
  }

const GetFormattedSqlDate = (inputData:string, type:number) => {
  var datime = new Date(inputData);
  var month = datime.getMonth() + 1;
  var day = datime.getDate();
  var year = datime.getFullYear();
  return year + "-" + (month < 10?'0'+month:month) + "-" + (day < 10?'0'+day:day)+` ${type==1?'00:00:00':'23:59:59'}`;
}

const searchMarker = (inputData:Array<IMarker>, value:string) => {
  let lowerCaseValue = value.toLowerCase();
  let arrayValues = splitData(lowerCaseValue, ' ');

  let result = arrayValues.map((item:string)=>{
    return filter(inputData,function(marker){
      return marker.name.toLowerCase().indexOf(item) !==-1 ||
              marker.farmId.toLowerCase().indexOf(item) !==-1;
    });
  })
  return result[0];
}


const splitData = (input:string, char:string) => {
  return split(input, char);
}

function addThousandsAndReplaceDecimal(number:string|number, separatorDecimal:string){
  //let newNumber = String(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  let newNumber = String(number);
  newNumber = newNumber.replace('.',separatorDecimal);
  newNumber = newNumber.replace(',',separatorDecimal);
  newNumber = newNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return newNumber;
}

function addZeroes(num:number) {
  return num.toFixed(Math.max(((num+'').split(".")[1]||"").length, 2));
}

function comeFromSmartCenter(){
  if(window.location.href.lastIndexOf("/smartmetrics/smartfarm/") == -1)
      return false;
  return true;
}

const MetadataDsc=(key:string, value:string|number)=>{
  switch(key) {
    case "interface_control":
      if(value == 0)
        return(`Desactivado`);
      else if(value == 1)
        return(`Activado`);
      else
        return(`Unknown`);
    case "interface_control_mode":
      if(value == 0)
        return(`Automático`);
      else if(value == 1)
        return(`Semi automático`);
      else if(value == 2)
        return(`Manual`);
      else
        return(`Unknown`);
    default:
      return(value);
  }
}

const GetRole = (project: string, me?:IMe) => {
  if(!me)
    return 0;

  const index = findIndex(me.projects, function(o){return o.id === project});

  if(index == -1)
      return -1;
  return me.projects[index].role;
}

export { 
  fetchAPI, checkHistorico, checkOnlineValues,
  GetFormattedSqlDate,
  searchMarker, zLibInflate,
  addThousandsAndReplaceDecimal, addZeroes,
  comeFromSmartCenter, MetadataDsc, GetRole, searchFarms,
}