import * as React from 'react';
import './FormsWidgets.css';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { OutlinedInput } from '@material-ui/core';

const userStyles = ({
    textField: {
      marginLeft: 1,
      marginRight: 15,
      backgroundColor: 'white',
      borderRadius:11,
      marginTop:15,
      //marginBottom: 20,
      //minWidth: 200
    },
    textFieldFullWidth: {
      width:'100%',
      backgroundColor: 'white',
      borderRadius: 11,
      marginTop:15,
      //marginBottom: 20

    },
    textFieldMiddleWidth: {
      marginLeft: 1,
      marginRight: 15,
      width:'30%',
      backgroundColor: 'white',
      borderRadius: 11,
      marginTop:15,
      //marginBottom: 20,
      //minWidth: 200

    },
    formControl: {
        marginRight: 0,
        backgroundColor: 'white',
        borderRadius: 11,
        height: '100%',
        marginTop:10,
        marginBottom:10,
      //marginBottom: 20,
        //width:'100%',
        minWidth: 200
    },
});

function returnStyle(typeStyle:number){
    switch(typeStyle){
        case 1:
            return userStyles.textField
        case 2:
            return userStyles.textFieldMiddleWidth
        case 3:
            return userStyles.textFieldFullWidth
        default:
            return userStyles.textField
    }
}

interface ITextFieldProps {
    name: string;
    fieldsData: any;
    id: string;
    handleFieldChange: Function;
    disabled: boolean;
    type: string;
    required:boolean;
    style:number;
    helper:string;
    maxText:number;
    noError:boolean;
    shrink:boolean;
    size:string;
    multiline?:boolean;
    rows?:number;
    className?:string
    onClick?:Function;
}

const TextFieldInput = (props: ITextFieldProps) => {

    let handler = (evt: any) => props.handleFieldChange(props.id, evt, props.type)
    // let validate = () => {
    //     if (props.canBeEmpty === false && props.deviceData[props.id] === '') {
    //         return false;
    //     }
    //     if (props.isNumber === true && (typeof props.deviceData[props.id]) === '')
    // }
    return (
        <TextField
            required={props.required}
            error={!props.noError}
            helperText={props.helper}
            multiline={props.multiline}
            id={props.id}
            type={props.type}
            label={props.name}
            style={returnStyle(props.style)}
            value={String(props.fieldsData[props.id]?props.fieldsData[props.id]:"")}
            onChange={handler}
            //margin="normal"
            onMouseDown={(evt: any) => props.onClick?props.onClick(evt):null} 
            variant="outlined"
            disabled={props.disabled}
            inputProps={{
                maxLength: props.maxText,
            }}
            InputLabelProps={{
                shrink: props.shrink,
            }}
            size={props.size=="small"?"small":"medium"}
            rows={props.rows}
            className={props.className}
        />
    );
}

interface ISelectorFieldProps {
    name: string;
    fieldsData: any;
    id: string;
    deviceData: any;
    handleFieldChange: Function;
    disabled?: boolean;
    //data: Array<any>;
    children?: any;
    style:number;
    required:boolean;
    keyDescription?:string;
    className?:string
    onClick?:Function;
}

const SelectorField = (props: ISelectorFieldProps) => {
    let handler = (evt: any) => props.handleFieldChange(props.id, evt)
    const inputLabel:any = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    return (
        <FormControl onMouseDown={(evt: any) => props.onClick?props.onClick(evt):null} required variant="outlined" style={userStyles.formControl} className={!props.className? 'filter-item-200px' : props.className }>
            <InputLabel ref={inputLabel} style={{fontSize:15, fontWeight:'bold'}} shrink={props.deviceData[props.id]?true:false} id={props.id}>
                <label style={{fontSize:'14px'}}>{props.name}</label>
            </InputLabel>
            <Select
                key={props.id}
                id={props.name}
                autoWidth
                value={props.deviceData[props.id]?props.deviceData[props.id]:-1}
                onChange={handler}
                disabled={props.onClick || props.disabled?true:false}
                input={
                    <OutlinedInput
                        notched
                        labelWidth={props.deviceData[props.id]?labelWidth:0}
                        name="age"
                        id="outlined-age-always-notched"
                    />
                }
                //labelWidth={labelWidth}
            >
            {props.fieldsData.map((item:any, index:number)=>{
                return (
                    <MenuItem key={index} value={item.id}>
                        <label style={{fontSize:'16px'}}>
                            {props.keyDescription?item[props.keyDescription]:item.name}
                        </label>
                    </MenuItem>
                );
            })}

            </Select>        
        </FormControl>
    );
}

interface ICheckBoxFieldProps {
    name: string;
    fieldsData: any;
    id: string;
    handleFieldChange: Function;
    disabled: boolean;
    children?: any;
    index?:number;
    shrink:boolean;
    size:string;
    type: string;
}

const CheckBoxField = (props: ICheckBoxFieldProps) => {

    let handler = (evt: any) => props.handleFieldChange(props.id, evt, props.type, props.index)
    return (
        <FormControlLabel
        control={
            <Checkbox
            
            size={props.size=="small"?"small":"medium"}
            disabled={props.disabled}
            checked={props.fieldsData[props.id]==1?true:false}
            onChange={handler}
            inputProps={{
            'aria-label': 'primary checkbox',
            }}
            color='default'
            />
            }
            label={props.name}
        />
    );
}

export { TextFieldInput, SelectorField, CheckBoxField };