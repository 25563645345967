import React from 'react';
import { IFarm, IIReport } from '../Library/types';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ListChildComponentProps } from 'react-window';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ArrowIcon from '@material-ui/icons/ArrowForwardIos';
import farmIcon from '../Assets/farm.png';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import sectorAviIcon from '../Assets/list-icon-avi.svg'
import sectorVacIcon from '../Assets/list-icon-vac.svg'
import sectorCerIcon from '../Assets/list-icon-cer.svg'
import reportIcon from '../Assets/images/report-image.svg';

const useStyles = makeStyles(createStyles({
    img: {
        objectFit: 'contain',
        width:40,
        height:40
    },
    root: {
        borderRadius: 0,
    },
    item: {
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 10px 5px rgba(0,0,0,0.31)',
        cursor: 'pointer',        
        position: 'relative',        
        backgroundColor:'#fff',
        paddingBottom: '10px',
        margin: '5px 10px',
        borderRadius: '15px',

    },
    icon: {
        paddingTop: '3px',
        paddingBottom: '3px',
        color: '#284770',
        verticalAlign: 'middle',
    },

    promotion: {
        //backgroundColor: '#f00',
        display: 'inline-block',
        padding: '3px 15px',
        //color: '#fff',
        verticalAlign: 'middle',
    },
    separator: {
        display: 'inline-block',
        borderRight: '1px solid rgba(0,0,0,0.31)',
        verticalAlign: 'middle',
        height: '100%',
        marginLeft: '15px'
    },
    textField: {
        marginLeft: '10px',
        marginRight: '10px',
        width: 60,
    },
    mainHeader: {
        whiteSpace:'nowrap',
        overflowX:'auto'
    }
}));

const FarmListItem = (props: ListChildComponentProps) => {
    const { index, style, data } = props;
    const classes = useStyles();
    let item: IIReport = data[index];


    return (        
        <div style={style} key={index} >
            <ListItem classes={{ root: classes.item }} onClick={item.onItemClick}>
                <ListItemIcon>              
                    {(() => {
                        switch (item.farmJournalType) {
                        case 0:
                            return <Avatar alt="Report Icon" src={reportIcon} classes={{
                                root: classes.root,
                                img: classes.img
                            }} />
                        case 1:
                            return <Avatar alt="Farm Icon" src={sectorAviIcon} classes={{
                                root: classes.root,
                                img: classes.img
                            }} />
                        case 2:
                            return <Avatar alt="Farm Icon" src={sectorCerIcon} classes={{
                                root: classes.root,
                                img: classes.img
                            }} />
                        case 3:
                            return <Avatar alt="Farm Icon" src={sectorVacIcon} classes={{
                                root: classes.root,
                                img: classes.img
                            }} />                        
                        default:
                            return null
                        }
                    })()}                          
                </ListItemIcon>
                <ListItemText 
                primary={`${item.farmJournalId}`} 
                secondary={`${new Date(item.journalDateTime).toLocaleString()} - ${item.farmId}`} 
                className={classes.mainHeader} />
            </ListItem>
        </div>
    );
}

FarmListItem.propTypes = {
    index: PropTypes.number,
    style: PropTypes.object,
} as any;

export default FarmListItem;