import IconButton from "@material-ui/core/IconButton";
import ClearAllIcon from "@material-ui/icons/LayersClearOutlined";
import FilterListIcon from "@material-ui/icons/FilterList";
import Toolbar from "@material-ui/core/Toolbar";
import { cloneDeep, filter, findIndex, groupBy } from "lodash";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import CreateModal from './Material/CreateDialog'

import { IBatch, IBuilding, IFarm, IMe, IOrigin } from "../Library/types";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import reportIcon from '../Assets/images/report-image.svg';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { SelectorField, CheckBoxField, TextFieldInput } from './FormsWidgets';
import React, { useEffect } from "react";
import { ValidateText } from "../Library/vc_general";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import DatePicker from './Material/DatePicker';
import { reports } from "../ApiClients/DummyData";

const useStyles = makeStyles(createStyles({
    img: {
        objectFit: 'contain',
        width:40,
        height:40
    },
    root: {
        borderRadius: 0,
    },
    item: {
        width: '100%',        
        cursor: 'pointer',        
        position: 'relative',        
        boxShadow: '0 3px 3px 3px rgba(0, 0, 0, 0.5);',
        backgroundColor:'#fff',
        marginTop: 20,
        borderRadius: '15px'
    },
    icon: {
        paddingTop: '3px',
        paddingBottom: '3px',
        color: '#284770',
        verticalAlign: 'middle',
    },

    promotion: {
        //backgroundColor: '#f00',
        display: 'inline-block',
        padding: '3px 15px',
        //color: '#fff',
        verticalAlign: 'middle',
    },
    separator: {
        display: 'inline-block',
        borderRight: '1px solid rgba(0,0,0,0.31)',
        verticalAlign: 'middle',
        height: '100%',
        marginLeft: '15px'
    },
    textField: {
        marginLeft: '10px',
        marginRight: '10px',
        width: 60,
    },
    mainHeader: {
        whiteSpace:'nowrap',
        overflowX:'auto'
    }
}));

type Config = {
    handleClose: Function;
    report: any;
};

const CreateReport = (props: Config) => {
    const {
    report,
    handleClose,
    } = props;

    console.log(report)
    const classes = useStyles();

    const [building, setBuilding] = React.useState(0);

    const [origin, setOrigin] = React.useState(0);

    const [lote, setLote] = React.useState<IBatch|undefined>(report.farmBatchId);


    const handleChangeBuilding = (event: React.SyntheticEvent, newValue: number) => {
        setOrigin(0);
        setBuilding(newValue);
    };

    const handleChangeOrigin = (event: React.SyntheticEvent, newValue: number) => {
        setOrigin(newValue);
    };

    const handleChangeDate = (id:string, evt:any) => {

    }

    const shouldDisableToday = (date:any) => {
        return false;
    }

    const handleChangeData = (idx: number, id:string, evt:any, type?:string) => {

    }
    const building_group = groupBy(report.farmJournalLines, x => x.buildingId);
    console.log(building_group)
    const buildings = Object.keys(building_group).map((line:any, idx00:number)=>{

        return(
            <Tab label={`N - ${line}`} />
        )
    })

    const GetOrigins = () => {
        const ret = building_group[Object.keys(building_group)[building]].map((item:any, idx00:number)=>{
            return(
                <Tab label={`${item.itemId} - ${item.itemName}`} />
            )
        });

        return ret;
    }

    const f_report_header = report.questions.map((question:any, index:number)=>{

        return(
            <TextFieldInput
                key={index}
                name={question.name}
                id={"answer"}
                fieldsData={question}
                handleFieldChange={(id:string, evt:any, type:string)=>handleChangeData((building+origin), id, evt, type)}
                // disabled={[50, 97, 98, 99].indexOf(item.estado) == -1?(n.group.indexOf(myRole) == -1?true:false):true}
                size="medium"
                shrink={true}
                disabled={true}
                type={question.inputType}
                required={question.required}
                style={1}
                helper={question.helper}
                maxText={question.maxText}
                //noError={n.required?this.verifyForm(0,n.id):true}
                noError={true}
                multiline={question.multiline}
                rows={3}
                className="color-sky-blue"
            />
        )
    });
    console.log( report.farmJournalLines[0])
    const f_report_lines = building_group[Object.keys(building_group)[building]][origin].questions.map((question:any, index:number)=>{
        console.log(question)
        return(
            <TextFieldInput
                key={index}
                name={question.name}
                id={"answer"}
                fieldsData={question}
                handleFieldChange={(id:string, evt:any, type:string)=>handleChangeData((building+origin), id, evt, type)}
                // disabled={[50, 97, 98, 99].indexOf(item.estado) == -1?(n.group.indexOf(myRole) == -1?true:false):true}
                size="medium"
                shrink={true}
                disabled={true}
                type={question.inputType}
                required={question.required}
                style={1}
                helper={question.helper}
                maxText={question.maxText}
                //noError={n.required?this.verifyForm(0,n.id):true}
                noError={true}
                multiline={question.multiline}
                rows={3}
                className="color-sky-blue"
            />
        )
    });

    return (
        <React.Fragment>
            <Dialog fullScreen={false} open={true} aria-labelledby="form-dialog-title" className='dialog-little'>
                <DialogTitle id="form-dialog-title">
                    {/* {`${GetTitle(report.farmJournalType)}`}   */}
                    {`${report.farmJournalId}`}
                    <p>
                        <a>{`${report.farmId}`}</a>
                    </p>            

                    
                    <div className='dialog-item-25'>
                        {DatePicker("journalDateTime", "Fecha parte", "dd/MM/yyyy", handleChangeDate, report.journalDateTime, true, shouldDisableToday, new Date('1970-01-01'))}
                    </div>       

                    <form key={0} noValidate autoComplete="off" style={{overflowX:'hidden', paddingTop:10, display:'flex', flexWrap:'wrap', gap:20}}>
                        {f_report_header}
                    </form>

                    <Box sx={{ maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper', marginTop: 3 }}>
                        <Tabs
                            value={building}
                            onChange={handleChangeBuilding}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            {buildings} 
                        </Tabs>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <TextFieldInput
                        key={0}
                        name={"Lote"}
                        id={"batchId"}
                        fieldsData={{ batchId: lote }}
                        handleFieldChange={(id:string, evt:any, type:string)=>handleChangeData(building, id, evt, type)}
                        // disabled={[50, 97, 98, 99].indexOf(item.estado) == -1?(n.group.indexOf(myRole) == -1?true:false):true}
                        disabled
                        size="medium"
                        shrink={true}
                        helper={""}
                        maxText={4000}
                        type={"text"}
                        required={true}
                        style={1}
                        //noError={n.required?this.verifyForm(0,n.id):true}
                        noError={true}
                        rows={3}
                        className="color-sky-blue"
                    />

                    <Box sx={{ maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper', marginTop: 3 }}>
                        <Tabs
                            value={origin}
                            onChange={handleChangeOrigin}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            {GetOrigins()} 
                        </Tabs>
                    </Box>

                    <form key={0} noValidate autoComplete="off" style={{overflowX:'hidden', paddingTop:10, display:'flex', flexWrap:'wrap', gap:20}}>
                        {f_report_lines}
                    </form>
                </DialogContent>
                <DialogActions>
                    <div style={{ display:'flex', gap:20 }}>
                        <Button onClick={()=>props.handleClose()} className="cancel-dialog">
                            {'Cerrar'}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default CreateReport;