import React from 'react';
import ReactDOM from 'react-dom';
import './indexApp.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

const Container = () => {
  return (
      <HashRouter>
      <Routes>
        <Route path="*" element={<App/>} />
      </Routes>
    </HashRouter>
  );
}

//ReactDOM.render(<Container />, document.getElementById('root'));

const container:any = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Container />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();