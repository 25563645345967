import React, { Component } from 'react';
import VirtualizedList from './Material/VirtualizedList';
import { IFarm, IMe, IFilter, IReport, IIReport } from '../Library/types';
import SearchField from './Material/SearchField';
import Loader from './Material/Loader';
import {searchFarms} from '../Library/helpers'
import FarmListItem from './FarmListItem';
import ReportListItem from './ReportListItem';

import OrderService from '../ApiClients/OrderService';
import { withParamsAndNavigate } from './Material/ReactRouter';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import ClearAllIcon from '@material-ui/icons/LayersClearOutlined';
import { SelectorField, CheckBoxField, TextFieldInput } from './FormsWidgets';
import {filter, includes, cloneDeep, groupBy} from 'lodash';
import Layout from './Layout';
import './Listado.css';
import Footer from './Footer';
import { reports } from '../ApiClients/DummyData';
import AvailableReports from "./ReportsAvailable";
import CreateReport from "./Report_00_disabled";
import CreateModal from './Material/CreateDialog'

interface IFarmsProps {
    myInfo?: IMe;
    version: string;
    params?:any;
    navigate?:any;
}

interface IFarmsState {
    report?: any;
    loading: boolean;

    valid: boolean;
    msg: string;

    openCreateReport: boolean;
    itemCreateReport?: IReport;

    modalType: number;
    typeAction: number;
    inputText: string;
    creationModalOpen: boolean;

    loadBeauty: boolean;
}

class Reports extends Component<IFarmsProps, IFarmsState> {
    constructor(props: IFarmsProps) {
        super(props);
        this.state = {
            loading: true,
            valid: false,
            msg: ""  ,
            openCreateReport: false,

            modalType: -1,
            typeAction: -1,
            inputText:'',
            creationModalOpen: false,

            loadBeauty: true
        };
    }

    componentDidMount() {
        const {business, reportId} = this.props.params;
        this.getReport(business, reportId);
    }

    resetLocalStorage = () => {
        localStorage.setItem('searchValueProduct', '')
    }

    handleCreateModal = (typeAction: number) => {
        switch(typeAction) {
            case 1:
                break;
            default:
                break;
        }
    }

    getReport = async (business:string, reportId:string) =>{
        let response = await OrderService.getReport(business, reportId);        
        if(response.valid){
            this.setState({
                valid: true,
                report: response.data[0],
                loadBeauty: false,
                creationModalOpen: false,
                loading: false,
                openCreateReport: true
            })
            /*
            if(response.length === 1) {
                this.resetLocalStorage();
                this.props.history.push(`/`)
                this.props.history.push(`/granja/${response[0].dataareaid}/${response[0].id}/productos`)
            } else{
                this.setState({
                    filteredFarms: searchFarms(response, this.state.searchValue),
                    farms: response,
                    loading:false
                })
            }*/            
        } else {
            this.setState({
                valid: false,
                inputText: response.msg,
                loadBeauty: false,
                creationModalOpen: true,
                modalType: 1,
                loading: false
                // reports: reports,
            })
        }
    }

    renderFarms=(farms:Array<IIReport>)=>{
        console.log(farms)
        if(farms.length == 0)
            return (
                <div style={{textAlign:'center', padding:50}}>
                    <p>
                        {`No tiene partes para visualizar.`}
                    </p>
                </div>
            )
        let items = farms.map((farm) => {
            return {
                ...farm,
                onItemClick: () => this.handleItemClick(farm),
            }
        })

        console.log(items)
        return(
            <VirtualizedList items={items} RowComponent={ReportListItem} itemSize={95} />
        );
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false });
    }

    handleItemClick = (farm: IIReport) => { 
        this.setState({
            inputText: `Detalle del parte ${farm.farmJournalId} próximamente...`,
            modalType: 1,
            creationModalOpen: true
        })     
        // this.resetLocalStorage();
        // this.props.navigate(`/report/create/${farm.id}`)
    }

    handleInfoClick = (farm: IIReport) => {
        // this.props.navigate(`/report/create/${farm.id}`)
    }

    handleFormCreateReport = (state:boolean) => {
        this.setState({
            openCreateReport: state,
        })
    }

    handleCreateReport = (report: IReport) => {
        console.log(report)
        this.setState({
            openCreateReport: false,
            itemCreateReport: undefined
        })
    }

    render() {
        const { report, openCreateReport, itemCreateReport} = this.state;
        const {myInfo} = this.props;
        console.log(report)
        return (
            <div>
                <Layout 
                    showLateralMenu={true}
                    version={this.props.version}
                    me={this.props.myInfo}
                    title={`${report?`Parte: ${report.farmJournalId}`: ''}`}
                    navigate={this.props.navigate}
                    loadbeauty={this.state.loading}
                />
                {!this.state.loading?
                    this.state.valid?
                        <div style={{ maxHeight: 'calc(100% - 112px)', overflowY:'auto' }}>
                            <div className='page-container'>
                                {/* <div className='search-container'>
  
                                    <SearchField 
                                        allowFilter 
                                        allowSearch={!this.state.loading} 
                                        value={this.state.searchValue} 
                                        onValueChange={this.handleValueChange} 
                                        placeholder="Buscar parte..." />
                                </div> */}
                            
                            </div>
                            
                            {/* <div className="App-header-options" style={{ marginRight:20 }}>
                                <div style={{ display:'flex', gap:'16px' }}>
                                    <div className={`App-header-options-item App-header-options-item-create ${!loadingAvailableReports?null:`App-header-options-item-disable`}`} onClick={()=>(!loadingAvailableReports?this.handleFormAvailableReport(true):null)}>
                                        {`+ Crear parte`}
                                    </div>
                                </div>
                            </div> */}
                            <div className='listado-container' style={{
                                    position: 'absolute',
                                    // top: '200px',
                                    top:'130px',
                                    left: 0,
                                    right: 0,
                                    bottom: '60px',
                                }}>
                                


                                {myInfo && report && openCreateReport?
                                    <CreateReport
                                        report={report}
                                        handleClose={()=>this.handleFormCreateReport(false)}
                                    />
                                :
                                    null
                                }
                                
                                {/* {openAvailableReports?
                                    <AvailableReports
                                        items={availableReports}
                                        handleClickReport={(item:IReport)=>this.handleClickAvailableReport(item)}
                                        handleClose={()=>this.handleFormAvailableReport(false)}
                                    />
                                :
                                    null
                                } */}

                                {/* {openCreateReport && itemCreateReport?
                                    <CreateReport
                                        item={itemCreateReport}
                                        handleCreateReport={(item:IReport)=>this.handleCreateReport(item)}
                                        handleClose={()=>this.handleFormCreateReport(false)}
                                        farm={dummyFarm}
                                    />
                                :
                                    null
                                } */}
                            </div>
                        </div>
                    :
                        <div style={{
                            position: 'absolute',
                            top: '120px',
                            left: 0,
                            right: 0,
                            bottom: '60px',
                            textAlign: 'center'
                        }}>
                            <p>
                                {`${this.state.msg}`}
                            </p>
                        </div>
                :
                    <div style={{marginLeft:'50%',marginTop:200}}>
                        <Loader loading={true}/>
                    </div>
                }

                {this.state.creationModalOpen?
                    <CreateModal
                        onCloseCreationModal={this.onCloseCreationModal}
                        isOpen={this.state.creationModalOpen}
                        inputText={this.state.inputText}
                        typeModal={this.state.modalType}
                        action={this.handleCreateModal}
                        typeAction={this.state.typeAction}
                        loadingModal={false}
                    />
                :
                    null
                }
                <Footer myInfo={this.props.myInfo} />
            </div>
        );
    }
}
export default withParamsAndNavigate(Reports);