import React, { Component } from 'react';
import ExitApp from '@material-ui/icons/ExitToAppOutlined';
import AppsIcon from '@material-ui/icons/AppsOutlined';
import BottomNavigation from '@material-ui/core/BottomNavigation/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction/BottomNavigationAction';
import { IMe } from '../Library/types';
import CreateModal from './Material/CreateDialog'
import { authContext } from '../Library/adalConfig';
import { withParamsAndNavigate } from './Material/ReactRouter';
import './Footer.css';

interface IFooterProps {        
    myInfo?:IMe;    
}
interface IFooterState{
    modalType: number;
    typeAction: number;
    inputText: string;
    creationModalOpen: boolean;
}

class Footer extends Component<IFooterProps, IFooterState>  {
    constructor(props: IFooterProps) {
        super(props);
        this.state = {
            modalType: -1,
            typeAction: -1,
            inputText:'',
            creationModalOpen: false
        };
    }
    
    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false });
    }

    onToggleCreationModal = () => {
        this.setState({ creationModalOpen: !this.state.creationModalOpen });
    }

    handleExitApp = () =>{
        this.setState({
            inputText:'¿Quiere cerrar la sesión?',
            modalType: 2,
            typeAction: 2,
            creationModalOpen: true
        })
    }

    handleNealiaHub = () =>{
        this.setState({
            inputText:'¿Quiere volver a las aplicaciones de Nealia?',
            modalType: 2,
            typeAction: 1,
            creationModalOpen: true
        })
    }

    handleCreateModal = (typeAction: number) => {
        switch(typeAction) {
            case 1:
                window.location.href = "https://hub.nealia.tech/private_aplicaciones";
                break;
            case 2:
                this.appLogOut();
                break;
            default:
                break;
        }
    }

    appLogOut = () => {
        authContext.logOut();
    }

    render(){
        return(
        <div className='footer'>
                <CreateModal
                    onCloseCreationModal={this.onCloseCreationModal}
                    isOpen={this.state.creationModalOpen}
                    inputText={this.state.inputText}
                    typeModal={this.state.modalType}
                    action={this.handleCreateModal}
                    typeAction={this.state.typeAction}
                    loadingModal={false}
                />
                {this.props.myInfo!==undefined?
                <BottomNavigation
                    showLabels
                    style={{ backgroundColor: '#6D6D6D' }}
                >
                    <BottomNavigationAction label={"Mis aplicaciones"} icon={<AppsIcon/>} onClick={this.handleNealiaHub} disabled={this.props.myInfo===undefined?true:false}/>
                    <BottomNavigationAction label={"Cerrar sesión"} icon={<ExitApp/>} onClick={this.handleExitApp} disabled={this.props.myInfo===undefined?true:false}/>
                </BottomNavigation>
                :
                <BottomNavigation
                showLabels
                style={{ backgroundColor: '#6D6D6D' }}
                >
                    {''}
                </BottomNavigation>
                }
        </div>
        )
    }
}
export default withParamsAndNavigate(Footer);