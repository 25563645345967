import { findIndex } from 'lodash';
import { fetchAPI } from '../Library/helpers';
import { IFarm } from '../Library/types';
import appData from './appData';

let baseURL = 'https://fnc-nealia-health-01.azurewebsites.net/api/';

class OrderService {
    
    static getFarms = async (refresh:boolean) => {
        try{
            let url = `${baseURL}loadFarms`
            if(appData.getListFarms() == undefined || refresh) {
                let response = await fetchAPI(url, 'GET', true, undefined, false);
                if(response.valid)
                    appData.setListFarms(response.data);
                else
                    appData.setListFarms([]);
            }
            return appData.getListFarms();
        }
        catch(err){
            appData.setListFarms(undefined);
            return [];
        }
    }

    static getFarm = async (business:string, id: string) => {
        const farms = appData.getListFarmDetail();

        const idx = findIndex(farms, function(o){return o.business == business && o.id == id});
        if(idx == -1) {
            let url = `${baseURL}loadFarmDetail?business=${business}&id=${id}`;
            let response = await fetchAPI(url, 'GET', true, undefined, false);

            if(!response.valid) 
                return null;

            const farm = response.data;
            appData.setListFarmDetail(farm);

            return farm;
        } else
            return farms[idx];
    }   
    
    static getProducts = async (refresh:boolean) => {
        try{
            let url = `${baseURL}loadProducts`
            if(appData.getListProducts() == undefined || refresh) {
                let response = await fetchAPI(url, 'GET', true, undefined, false);
                appData.setListProducts(response);
            }
            return appData.getListProducts();
        }
        catch(err){
            appData.setListProducts(undefined);
            return [];
        }
    }
    
    static getPedidos = async (refresh:boolean) => {
        try{
            let url = `${baseURL}loadPedidos`

            if(appData.getListPedidos() == undefined || refresh) {
                let response = await fetchAPI(url, 'GET', true, undefined, false);
                appData.setListPedidos(response);
            }
            return appData.getListPedidos();
        }
        catch(err){
            appData.setListPedidos(undefined);
            return [];
        }
    }
   
    static getReports = async () => {
        try{
            let url = `${baseURL}loadReports`
            
            let response = await fetchAPI(url, 'GET', true, undefined, false);
            return response;
            // if(response.valid)
            //     return response.data;
            // else
            //     return [];
        }
        catch(err){
            return {
                valid: false,
                msg: err
            };
        }
    }
   
    static getReport = async (business:string, reportId: string) => {
        try{
            let url = `${baseURL}loadReport?business=${business}&reportId=${reportId}`
            
            let response = await fetchAPI(url, 'GET', true, undefined, false);
            return response;
            // if(response.valid)
            //     return response.data;
            // else
            //     return [];
        }
        catch(err){
            return {
                valid: false,
                msg: err
            };
        }
    }

    static getAvailableReports = async (business:string) => {
        try{
            const reports = appData.getListAvailableReports();

            // if(reports.length > 0) 
            //     return {valid:true, data: reports};

            let url = `${baseURL}loadAvailableReports?business=${business}`
            
            let response = await fetchAPI(url, 'GET', true, undefined, false);
            if(response.valid)
                appData.setListAvailableReports(response.data);
            console.log(reports)
            return response;
        }
        catch(err){
            return {
                valid: false,
                msg: err
            };
        }
    }

    static getAvailableReport = async (business:string, id:string) => {
        try{
            let url = `${baseURL}loadAvailableReport?business=${business}&id=${id}`
            
            let response = await fetchAPI(url, 'GET', true, undefined, false);
            return response.data;
        }
        catch(err){
            return {
                valid: false,
                msg: err
            };
        }
    }

    static getPedidosRelated = async (refresh:boolean) => {
        try{
            let url = `${baseURL}loadRelatedPedidos`

            if(appData.getListPedidosRelated() == undefined || refresh || appData.getListPedidosRelated().length == 0) {
                let response = await fetchAPI(url, 'GET', true, undefined, false);
                appData.setListPedidosRelated(response);
            }
            return appData.getListPedidosRelated();
        }
        catch(err){
            appData.setListPedidosRelated(undefined);
            return [];
        }
    }

    static saveReport = async (data: any, business:string) => {
        try {

            let url = `${baseURL}saveReport?business=${business}`
            let originalDate = new Date().getTime();
            let response = await fetchAPI(url, 'POST', true, {...data}, false);

            return response;
            // if(response.valid)
            //     return response.data;

        } catch (err) {
            console.log(err);
            return {
                valid: false,
                msg: "Error en guardar el parte."
            };
        }
    }

    
    static saveData = async (action:number, projectId:string, data: any) => {
        try {

            let url = `${baseURL}saveData?action=${action}&projectId=${projectId}`
            let originalDate = new Date().getTime();
            let response = await fetchAPI(url, 'POST', true, {...data}, false);
            if(response.valid)
                return response.data;

        } catch (err) {
            console.log(err);
            return {
                valid: false,
                msg: err
            };
        }
    }

    static me = async () => {
        try{
            let url = `${baseURL}me`
            let response = await fetchAPI(url, 'GET', true, undefined, false);
            //console.log(response)
            return response;
        }
        catch(err){
            return null;
        }
    }
    
    static metricService = async (metricType:number) => { // Llamadas de registro de métricas //
        try {
            let url = `${baseURL}metrics?metricType=${metricType}&origin=${window.location.href}`
            await fetchAPI(url, 'PUT', true, undefined, false);
        } catch(err) {
        }
    }
}
export default OrderService;